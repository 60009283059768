import React, { FC } from "react";
import { Grid, Button, FormControl } from "utils/material-ui-core";
import yn from "yn";
import { useEnv } from "hooks/useEnv";
import ProductAddToButton from "component-related-product-card/ProductAddToButton";
import GiftRegistryButton from "component-related-product-card/GiftRegistryButton";
import { useIsTradeOrContract } from "hooks/useIsTradeOrContract";
import { useCountry } from "hooks/useCountry";
import { usePageContent } from "customProviders/LocationProvider";
import { checkCountryIsEu } from "utils/checkCountryIsEu";
import { useRhUserAtomValue } from "hooks/atoms";
import useSession from "hooks-use-session/useSession";
import { ERelatedProductActionsProps } from "./types";
import memoize from "utils/memoize";

export const ERelatedProductActions: FC<ERelatedProductActionsProps> = ({
  isDisabled,
  selectedIds,
  isAuthenticated,
  hasInventory,
  isPreOrder,
  handleAddToCartClick,
  handleAddToWishlistClick,
  handleAddToGiftRegistryClick,
  extraFormFragments,
  ...rest
}) => {
  const env = useEnv();
  const { userType } = useRhUserAtomValue();
  const { authenticated } = useSession();
  const isContractTrade = ["contract", "trade"].includes(
    userType?.toLowerCase() ?? ""
  );
  const isNotTradeOrContract = !useIsTradeOrContract();
  const country = useCountry();
  const countryIsEu = checkCountryIsEu({ country });
  const { pageContent } = usePageContent();

  return (
    <Grid container justify="space-between" alignItems="center" spacing={2}>
      {!!extraFormFragments &&
        extraFormFragments.map((form, index) => (
          <React.Fragment key={index}>{form}</React.Fragment>
        ))}
      <Grid item xs={12} sm={12} md={12}>
        {/* <Grid item xs={12} sm={rest?.isCustomProduct ? 12 : 6} md={12}> */}
        <FormControl fullWidth>
          <Button
            id="component-related-product-card_add-to-cart-btn"
            variant="contained"
            color="primary"
            onClick={handleAddToCartClick}
            disabled={isDisabled}
            data-testid="add-to-cart-dialog-opener"
            className={`text-white focus-visible:outline focus-visible:outline-[1px] focus-visible:outline-blue-700 ${
              isDisabled
                ? "bg-[#DDDDDD]"
                : "bg-black hover:!bg-gray-1 hover:!border-gray-1"
            }`}
            tabIndex={0}
          >
            {isPreOrder
              ? `${pageContent?.PRE_ORDER}`
              : `${pageContent?.ADD_TO_CART}`}
          </Button>
        </FormControl>
      </Grid>
      {yn(env.FEATURE_ADD_TO_WISHLIST) &&
        !isContractTrade &&
        authenticated &&
        !countryIsEu && (
          <Grid item xs={12} sm={rest?.isCustomProduct ? 12 : 6} md={12}>
            <FormControl
              margin="none"
              data-testid={"add-to-wish-list"}
              fullWidth
            >
              <ProductAddToButton
                variant="outlined"
                color="primary"
                onClick={handleAddToWishlistClick}
                disabled={isDisabled}
                text="ADD TO WISHLIST"
                id="component-relatedProductActions_addToWishlist-btn"
                isCustomProduct={rest?.isCustomProduct}
              />
            </FormControl>
          </Grid>
        )}
      {yn(env.FEATURE_ADD_TO_GIFT_REGISTRY) &&
        isNotTradeOrContract &&
        !countryIsEu && (
          <Grid item xs={12} sm={12} md={12}>
            {authenticated && (
              <FormControl fullWidth>
                <GiftRegistryButton
                  handleOnClick={handleAddToGiftRegistryClick}
                  disabled={isDisabled}
                  isCustomProduct={rest?.isCustomProduct}
                />
              </FormControl>
            )}
          </Grid>
        )}
    </Grid>
  );
};

ERelatedProductActions.defaultProps = {};
export default memoize(ERelatedProductActions);
