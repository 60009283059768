import React, { FC, useCallback } from "react";
import {
  Grid,
  Typography,
  FormControlLabel,
  Checkbox
} from "utils/material-ui-core";
import OptionsDetailsList from "component-options-details-list";
import memoize from "utils/memoize";
import useUserContext from "hooks/useUserContext";
import { formatCurrency } from "utils/currencyHelpers";
import { usePageContent } from "customProviders/LocationProvider";
import { MonogramChoiceProps } from "./types";

export const MonogramChoice = memoize<FC<MonogramChoiceProps>>(
  ({
    fee,
    currencyLabel,
    currencySymbol,
    currency,
    monogramOrder,
    personalizeInfo,
    readOnly = false,
    openMonogramDialog,
    removeMonogram,
    loading,
    isOptionsFromMonogramOrder = false
  }) => {
    const userContext = useUserContext();
    const { pageContent } = usePageContent();

    const {
      borderCode,
      description,
      fontColorCode,
      lines,
      fontName,
      borderName
    } = monogramOrder;
    const isMonogrammed = !!description && !!lines && lines.length;
    const options: ProductItemOption[] = isMonogrammed
      ? [
          {
            id: "styleDisplayName",
            label: isOptionsFromMonogramOrder
              ? monogramOrder["styleDisplayName"] || monogramOrder["fontCode"]
              : personalizeInfo?.selectedFont?.displayName ||
                fontName ||
                description,
            optionType: "Style",
            sortPriority: 1
          },
          {
            id: "borderCode",
            label: isOptionsFromMonogramOrder
              ? monogramOrder["borderDisplayName"] ||
                monogramOrder["borderCode"]
              : personalizeInfo?.selectedBorder?.displayName ||
                borderCode ||
                "",
            optionType: "Border",
            sortPriority: 3
          },
          {
            id: "fontColorCode",
            label: isOptionsFromMonogramOrder
              ? monogramOrder["fontColorDisplayName"] ||
                monogramOrder["fontColorCode"]
              : personalizeInfo?.selectedColor?.displayName ||
                fontColorCode ||
                "",
            optionType: "Color",
            sortPriority: 4
          },
          ...(!personalizeInfo
            ? [
                {
                  id: "borderName",
                  label: borderName || "",
                  optionType: "Border",
                  sortPriority: 2
                }
              ]
            : [])
        ]
          .filter(item => item.label)
          .concat(
            lines?.map((text, index) => {
              return {
                id: `text ${index}`,
                label: text,
                optionType: "Text",
                sortPriority: 3 + index
              };
            })
          )
      : [];

    const handleMonogramChange = useCallback(() => {
      if (!loading) {
        if (isMonogrammed) {
          removeMonogram?.();
        } else {
          openMonogramDialog?.(true);
        }
      }
    }, [isMonogrammed, removeMonogram, openMonogramDialog, loading]);

    const handleRemoveMonogram = useCallback(() => removeMonogram(), []);
    const handleOpenMonogram = useCallback(() => openMonogramDialog(true), []);
    return (
      <>
        {!readOnly && (
          <Grid item xs={12} className="!mb-2">
            <FormControlLabel
              control={
                <Checkbox
                  data-testid="monogram-checkbox"
                  checked={Boolean(isMonogrammed)}
                  onChange={handleMonogramChange}
                  className={`md:!w-6 md:!h-6 !w-[18px] !h-[18px] !text-gray-600 ${
                    !loading ? "cursor-pointer" : "cursor-default"
                  }`}
                  style={{ cursor: !loading ? "pointer" : "default" }}
                />
              }
              label={
                <Typography component="span">
                  {pageContent?.ADD_MONOGRAM_FOR || "Add Monogram for"}{" "}
                  {currency
                    ? formatCurrency(fee, currency)
                    : `${currencySymbol}${fee}`}
                </Typography>
              }
              className={`${!loading ? "cursor-pointer" : "cursor-default"}`}
            />
          </Grid>
        )}
        {isMonogrammed && (
          <Grid item container className="!mb-2">
            {isMonogrammed && (
              <Grid item xs={12}>
                <Typography className="!text-gray-600 text-xs">
                  {pageContent?.PERSONALIZATION}
                </Typography>
              </Grid>
            )}
            {personalizeInfo ? (
              <OptionsDetailsList options={options} uppercase={false} />
            ) : (
              <OptionsDetailsList
                options={options.filter(item => item.id !== "borderCode")}
                uppercase={false}
              />
            )}
            {!readOnly && (
              <>
                <Grid onClick={() => openMonogramDialog(true)}>
                  <Typography
                    className="!mr-9 !uppercase cursor-pointer underline !text-[11px]"
                    tabIndex={0}
                    role="button"
                    aria-label={`${
                      pageContent?.EDIT || pageContent?.cartOverview?.EDIT
                    } Monogram`}
                  >
                    {pageContent?.EDIT || pageContent?.cartOverview?.EDIT}
                  </Typography>
                </Grid>

                <Grid onClick={handleRemoveMonogram}>
                  <Typography
                    tabIndex={0}
                    role="button"
                    aria-label={`${
                      pageContent?.REMOVE || pageContent?.cartOverview?.REMOVE
                    } Monogram`}
                    className="!uppercase cursor-pointer underline !text-[11px]"
                  >
                    {pageContent?.REMOVE}
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
        )}
      </>
    );
  }
);
