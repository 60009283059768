import React, { useState, useCallback, useMemo } from "react";
import {
  Button,
  Theme,
  makeStyles,
  createStyles,
  DialogContent
} from "@material-ui/core";
import Drawer from "component-drawer";
import useRHStyles from "hooks/useRHStyles";
import RHDialogTitle from "component-rh-dialog-title";
import memoize from "utils/memoize";
import InternationalPostalCodeDialog from "dialog-international-postal-code";
import { usePageContent } from "customProviders/LocationProvider";
import {
  CHECK_FOR_REPLACEMENT_PARTS,
  REPLACEMENT_MODAL
} from "resources/page-level-resources-schemas/products.json";
import TailwindDrawer from "@RHCommerceDev/component-tailwind-drawer";
import { useEnv } from "hooks/useEnv";
import yn from "yn";

const useStyles = props =>
  makeStyles((theme: Theme) =>
    createStyles({
      button: {
        padding: theme.spacing(1, 2, 1, 2),
        cursor: props.disabled ? "default" : "pointer",
        textTransform: "uppercase",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        fontSize: theme.typography.pxToRem(10),
        color: "#FFF"
      },
      content: {
        overflow: "auto"
      }
    })
  );
const ReplacementPartButton = ({ postalCode, children, ...rest }) => {
  const classes = useStyles({})();
  const { pageContent } = usePageContent();
  const rhStyles = useRHStyles({
    keys: ["inStockOnSaleDrawer"]
  });
  const env = useEnv();
  const FEATURE_TAILWIND_COMPONENTS = yn(env.FEATURE_TAILWIND_COMPONENTS);
  const DrawerComponent = FEATURE_TAILWIND_COMPONENTS ? TailwindDrawer : Drawer;
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [postalCodeDialog, setPostalCodeDialog] = useState(false);
  const postalCodeAvailable = useMemo(() => {
    return (
      !!postalCode &&
      postalCode !== "null" &&
      postalCode !== "undefined" &&
      postalCode !== "false"
    );
  }, [postalCode]);

  const onClick = useCallback(() => {
    postalCodeAvailable ? setDrawerOpen(true) : setPostalCodeDialog(true);
  }, [postalCodeAvailable]);

  const handleDrawerOpen = useCallback(() => {
    setDrawerOpen(true);
  }, []);

  const handlePostalCodeDialogClose = useCallback(() => {
    setPostalCodeDialog(false);
  }, []);

  return (
    <>
      <Button
        id={"component-replacement-part-button"}
        variant="contained"
        color="primary"
        size="small"
        onClick={onClick}
        className={classes.button}
      >
        {pageContent?.CHECK_FOR_REPLACEMENT_PARTS ??
          CHECK_FOR_REPLACEMENT_PARTS}
      </Button>
      <DrawerComponent
        id={"component-replacement-part-button_drawer"}
        transitionDuration={0}
        anchor="top"
        open={drawerOpen}
        classes={{ paper: rhStyles.inStockOnSaleDrawer }}
      >
        <RHDialogTitle
          title={
            pageContent?.REPLACEMENT_MODAL?.REPLACEMENT_PARTS?.toLocaleUpperCase() ??
            REPLACEMENT_MODAL.REPLACEMENT_PARTS
          }
          onClose={(event, reason) => setDrawerOpen(false)}
        />
        <DialogContent className={classes.content}>{children}</DialogContent>
      </DrawerComponent>

      <InternationalPostalCodeDialog
        open={postalCodeDialog}
        onConfirm={handleDrawerOpen}
        onClose={handlePostalCodeDialogClose}
      />
    </>
  );
};

export default memoize(ReplacementPartButton);
