import React, { FC, Fragment, useCallback, useMemo } from "react";
import useState from "hooks/useState";
import useAppData from "hooks/useAppData";
import { Typography, Grid } from "utils/material-ui-core";
import ReplacementPartProductCard from "component-replacement-part-product-card";
import { useQuery } from "@apollo/client";
import { queryReplacementParts } from "graphql-client/queries/replacement-parts";
import RHLink from "component-rh-link";
import RHDivider from "component-rh-divider";
import he from "he";
import { MonogramOrderProps } from "page-monogram-dialog";
import { getCountryCodeFromPostalCode } from "utils/postalcode-validation";
import memoize from "utils/memoize";
import ReplacementPartSkeleton from "./ReplacementPartSkeleton";
import useTypographyStyles from "hooks/useTypographyStyles";
import InternationalPostalCodeDialog from "dialog-international-postal-code";
import useSite from "hooks/useSite";
import { usePageContent } from "customProviders/LocationProvider";
import {
  REPLACEMENT_MODAL,
  ENTER_LOCATION
} from "resources/page-level-resources-schemas/products.json";
import { OPTIONS_AVAILABLE_FOR } from "resources/page-level-resources-schemas/product.json";

export interface ReplacementPartDialogContentProps {
  name: string;
  postalCode: string;
  items: Maybe<ReplacementPartItem[]>;
  salePriceLabel: string;
  monogramOrder: MonogramOrderProps;
  loading?: boolean;
}

export const ReplacementPartComponent: FC<
  ReplacementPartDialogContentProps
> = ({ name, postalCode, items, salePriceLabel, monogramOrder, loading }) => {
  const [postalCodeDialog, setPostalCodeDialog] = useState(false);
  const { pageContent } = usePageContent();

  const handlePostalCodeDialogOpen = useCallback(e => {
    e.preventDefault();
    setPostalCodeDialog(true);
  }, []);

  const handlePostalCodeDialogClose = useCallback(() => {
    setPostalCodeDialog(false);
  }, []);

  const typographyStyles = useTypographyStyles({
    keys: ["rhBaseBody1", "rhBaseCaption", "rhBaseH2"]
  });

  const postalCodeAvailable = useMemo(() => {
    return (
      !!postalCode &&
      postalCode !== "null" &&
      postalCode !== "undefined" &&
      postalCode !== "false"
    );
  }, [postalCode]);

  return (
    <Grid
      container
      spacing={2}
      style={{ width: "100%" }}
      id={"component-replacement-part-dialog-content"}
    >
      <Grid item xs={12}>
        <Typography
          className={typographyStyles.rhBaseH2}
          component="p"
          paragraph
        >
          {pageContent?.REPLACEMENT_MODAL?.REPLACEMENT_PARTS?.toLocaleUpperCase() ??
            REPLACEMENT_MODAL?.REPLACEMENT_PARTS}{" "}
          - {he.decode(name)}
        </Typography>
      </Grid>
      {loading ? (
        <ReplacementPartSkeleton />
      ) : (
        <>
          <Grid item xs={12}>
            {postalCodeAvailable ? (
              <Typography component="p" paragraph gutterBottom>
                {pageContent?.OPTIONS_AVAILABLE_FOR ?? OPTIONS_AVAILABLE_FOR}{" "}
                {pageContent?.REPLACEMENT_MODAL?.ZIP ?? REPLACEMENT_MODAL?.ZIP}/
                {pageContent?.REPLACEMENT_MODAL?.POSTAL ??
                  REPLACEMENT_MODAL?.POSTAL}{" "}
                <RHLink
                  data-testid={"in-stock-link"}
                  style={{
                    display: "initial",
                    color: "black",
                    fontSize: "inherit",
                    letterSpacing: "inherit",
                    fontWeight: "inherit"
                  }}
                  onClick={handlePostalCodeDialogOpen}
                  underline="always"
                >
                  {postalCode}
                </RHLink>
              </Typography>
            ) : (
              <Typography
                className={typographyStyles.rhBaseCaption}
                component="p"
                paragraph
                gutterBottom
              >
                <RHLink onClick={handlePostalCodeDialogOpen} underline="always">
                  {pageContent?.ENTER_LOCATION ?? ENTER_LOCATION}
                </RHLink>
              </Typography>
            )}
          </Grid>
          {(items ?? []).length > 0 ? (
            <>
              {items?.map((item, index) => (
                <Fragment key={`ReplacementPartProduct-${item.fullSkuId}`}>
                  <Grid item xs={12}>
                    <ReplacementPartProductCard
                      data={item}
                      productId={item?.productId ?? ""}
                      productDisplayName={item?.name ?? ""}
                      monogramOrder={monogramOrder}
                      salePriceLabel={salePriceLabel}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {index < items.length - 1 && <RHDivider />}
                  </Grid>
                </Fragment>
              ))}
            </>
          ) : (
            <Grid item xs={12}>
              <Typography className={typographyStyles.rhBaseBody1}>
                {pageContent?.REPLACEMENT_MODAL?.NOT_FOUND_MESSAGE ??
                  REPLACEMENT_MODAL?.NOT_FOUND_MESSAGE}
                .
              </Typography>
            </Grid>
          )}
        </>
      )}
      <InternationalPostalCodeDialog
        open={postalCodeDialog}
        onClose={handlePostalCodeDialogClose}
      />
    </Grid>
  );
};

const RPC = memoize(ReplacementPartComponent);

const ReplacementPartDialogContent: FC<{
  productId: string;
  fullSkuId: string;
  name: string;
  postalCode?: string;
  monogramOrder: MonogramOrderProps;
  salePriceLabel: string;
}> = props => {
  const { app } = useAppData();
  const postalCode = props.postalCode || app.postalCode || "";
  const countryCode = getCountryCodeFromPostalCode(postalCode) ?? "";
  const siteId = useSite();

  const {
    loading: loadingReplacementParts,
    data: { replacementParts } = {} as Query
  } = useQuery<Query>(queryReplacementParts, {
    variables: {
      productId: props.productId,
      fullSkuId: props.fullSkuId,
      postalCode: postalCode,
      country: countryCode,
      filter: app.saleContextFilter,
      siteId
    },
    skip: !postalCode
  });

  return (
    <RPC
      name={props.name}
      items={replacementParts?.instockReplacementParts}
      postalCode={postalCode}
      salePriceLabel={props.salePriceLabel}
      loading={loadingReplacementParts}
      monogramOrder={props.monogramOrder}
    />
  );
};

export default memoize(ReplacementPartDialogContent);
