import React, { FC } from "react";
import InStockOnSaleLink from "component-in-stock-on-sale-link";
import { usePageContent } from "customProviders/LocationProvider";
import { useRelatedProductCardData } from "./useRelatedProductCardData";
import { InStockDialogContent } from "component-in-stock-dialog-content";
import { RelatedProductCardProps } from "./types";
import memoize from "utils/memoize";

export const RelatedProductInStockButton: FC<RelatedProductCardProps> = memoize(
  (props: RelatedProductCardProps) => {
    const { pageContent } = usePageContent();

    const {
      inStockReady,
      isShellProduct,
      heroImageLevel,
      monogramOrder,
      data,
      isMetaProduct,
      pli,
      authenticated,
      maxOrderQty,
      customProductConfig,
      userType,
      postalCode,
      isNewPDPLayout
    } = useRelatedProductCardData(props);

    return (
      <>
        <InStockOnSaleLink
          styles={
            inStockReady
              ? {}
              : isNewPDPLayout
              ? {
                  color: "#000",
                  fontFamily: "RHSans Thin",
                  fontSize: "11px",
                  fontStyle: "normal",
                  fontWeight: 300,
                  lineHeight: "120%",
                  letterSpacing: "0.44px",
                  textTransform: "uppercase",
                  display: "flex",
                  placeContent: "center",
                  textDecoration: "underline"
                }
              : {}
          }
          id="component-relatedProductCartd_view-in-stock-btn"
          text={inStockReady ? inStockReady : pageContent?.IN_STOCK}
          drawerTitle={pageContent?.IN_STOCK}
          postalCode={postalCode}
          enableArrow={heroImageLevel}
          isActiveInstockModal={props?.isActiveInstockModal}
          mainProduct={props?.productId}
          productId={data?.id}
        >
          <InStockDialogContent
            monogramOrder={monogramOrder}
            productId={data?.id}
            metaProduct={isMetaProduct}
            productName={data?.displayName}
            postalCode={pli?.sku?.delivery?.postalCode ?? postalCode}
            isAuthenticated={authenticated}
            productPrice={pli?.sku?.info?.skuPriceInfo}
            customInfo={customProductConfig}
            salePriceLabel={data?.priceRangeDisplay?.salePriceLabel ?? ""}
            userType={userType || ""}
            maxOrderQty={maxOrderQty}
            multiSkuAdditionalMessage={data?.multiSkuAdditionalMessage ?? ""}
          />
        </InStockOnSaleLink>
      </>
    );
  }
);
