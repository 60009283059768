import {
  InStockDialogContent,
  OnSaleDialogContent
} from "component-in-stock-dialog-content";
import RHImage from "component-rh-image";
import RHLinkListCollapse from "component-rh-link-list/RHLinkListCollapse";
import RHLinkListDrawer from "component-rh-link-list/RHLinkListDrawer";
import AddToCartDialog from "dialog-add-to-cart";
import AddToGiftRegistryDialog from "dialog-add-to-gift-registry";
import AddToWishlistDialog from "dialog-add-to-wishlist";
import InternationalPostalCodeDialog from "dialog-international-postal-code";
import PostalCodeDialog from "dialog-postal-code";
import SurchargeInfoDialog from "dialog-surcharge-info";
import { Hidden, processEnvServer } from "hooks/useSsrHooks";
import MonogramDialog from "page-monogram-dialog";
import React, { FC } from "react";
import {
  Divider,
  Grid,
  TextField,
  Typography,
  useTheme
} from "utils/material-ui-core";

import PrintablePDPTemplate from "@RHCommerceDev/component-printable-pdp-template";
import { AddOnCheckBox } from "@RHCommerceDev/component-product-addon-checkbox";
import analyticsLoader from "analytics/loader";

import ProductOptionDropdown from "component-product-option-dropdown";
import RelatedProductActions from "component-related-product-actions";
import ReplacementPartButton from "component-replacement-part-button";
import ReplacementPartDialogContent from "component-replacement-part-dialog-content";
import RHLink from "component-rh-link";
import Sku from "component-sku";
import { usePageContent } from "customProviders/LocationProvider";

import he from "he";
import { useEnv } from "hooks/useEnv";
import useTypographyStyles from "hooks/useTypographyStyles";
import { isEmpty, sortBy } from "lodash";

import { ON_SALE } from "resources/page-level-resources-schemas/product.json";
import { SHIPPING_SURCHARGE } from "resources/page-level-resources-schemas/products.json";
import { checkCountryIsEu } from "utils/checkCountryIsEu";
import { IMAGE_ASPECT_RATIO, MONOGRAM_ORDER_DEFAULT } from "utils/constants";
import { ButtonBase } from "utils/material-ui-core";
import memoize from "utils/memoize";
import yn from "yn";
import { MonogramChoice } from "./MonogramChoice";
import { useStyles } from "./RelatedProductCard.useStyles";
import { RelatedProductCardProps } from "./types";

import { useRelatedProductCardData } from "./useRelatedProductCardData";
import { RelatedProductInStockButton } from "./RelatedProductInStockButton";
import { RelatedProductOnSaleButton } from "./RelatedProductOnSaleButton";
import useMediaQueryValues from "@RHCommerceDev/hooks/useMediaQueryValues/useMediaQueryValues";
import { defaultRideAlongData } from "page-product";
import RHRPriceDisplay from "@RHCommerceDev/component-rh-price-range-display/RHRPriceDisplay";
import { getPriceUserType } from "component-product-grid";
import cn from "classnames";
import { getSelectorsByUserAgent } from "react-device-detect";
import { getReqContext } from "utils/reqContext";
import useGetPDPRedirectPath from "hooks/useGetPDPRedirectPath";

enum PriceRangeType {
  RHPriceRange = "RHPriceRangeDisplay",
  RHRUniversalPriceRange = "RHRUniversalPriceRangeDisplay"
}

export const RelatedProductCard: FC<RelatedProductCardProps> = (
  props: RelatedProductCardProps
) => {
  const theme = useTheme();
  const localClasses = useStyles();
  const { pageContent } = usePageContent();
  const env = useEnv();
  const FEATURE_PDP_CART_DELIVERY = yn(env.FEATURE_PDP_CART_DELIVERY);
  const typographyStyles = useTypographyStyles({
    keys: [
      "rhBaseH3",
      "rhBaseCaption",
      "rhBaseBody1",
      "rhBaseBody2",
      "uppercaseText"
    ]
  });

  const { smUp, lgUp, mdUp, smDown, xsDown } = useMediaQueryValues();

  const {
    inStockReady,
    isShellProduct,
    monogramOrder,
    data,
    isMetaProduct,
    pli,
    authenticated,
    maxOrderQty,
    customProductConfig,
    userType,
    postalCode,
    isNewPDPLayout,
    isNorthAmerica,
    swatchShipMessage,
    loading,
    giftCardTo,
    giftCardFrom,
    rugTrim,
    customOptions,
    isAllCustomOptionSelected,
    isDisabled,
    monogrammable,
    country,
    isConcierge,
    showItemNumber,
    isCustomOptionSelectionValid,
    params,
    multiSkuComponentsDataV2,
    skuAvailabilityStatus,
    giftCardValueExceed,
    spoOverrideMessage,
    skuDetails,
    selectedOptions,
    qty,
    addOnsExist,
    selectedAddOnsNotFullyConfigured,
    isEnableCustomRug,
    isCustomRug,
    selectedIds,
    customSelectedOptions,
    addonDataAddToProject,
    productDetails,
    shouldDisplayAddOns,
    addOnsData,
    swatch,
    postalCodeDialog,
    internationalFlag,
    surchargeInfoDialog,
    cartDialog,
    metaSkuPricing,
    confirmed,
    addonDataAddToCart,
    panelProdData,
    panelProdConfigurationData,
    addToCartItemDetails,
    wishlistDialog,
    giftRegistryDialog,
    productImageUrl,
    monogramDialog,
    lineItemIndex,
    saleContextFilter,
    isLineItemImageUrlFailed,
    saleMessageForPriceComponent,
    lineItem,
    swatchGroups,
    skuInventoryMessage,

    showInStockButton,
    isMultiSku,
    configureAvailableOptions,
    onProductConfigChange,
    setGiftCardTo,
    setGiftCardFrom,
    onRugConfigChange,
    handleDiameterInFeetChange,
    handleDiameterInInchesChange,
    handleWidthInFeetChange,
    handleWidthInInchesChange,
    handleLengthInFeetChange,
    handleLengthInchesChange,
    setSurchargeInfoDialog,
    setMonogramDialog,
    setMonogramOrder,
    setPostalCodeDialog,
    setQty,
    setIsQtyChanged,
    handleAddToCartClick,
    handleAddToWishlistClick,
    handleAddToGiftRegistryClick,
    handleAddonData,
    showSaleButton,
    handleCloseAddToCartDialog,
    setConfirmed,
    onAddOnConfigChange,
    setWishlistDialog,
    setGiftRegistryDialog,
    getLocationSearch,
    triggerAnalyticsEvent,
    setIsLineItemImageUrlFailed,
    setRideAlongData,
    heroImageLevel
  } = useRelatedProductCardData(props);

  const isProductOnSale = data?.id === params?.productId || data?.onSale;

  const req = getReqContext();
  const userAgent = req?.headers["user-agent"];

  let mobile = false;
  if (userAgent) {
    const { isMobile } = getSelectorsByUserAgent(userAgent);
    mobile = isMobile;
  }
  const redirectPath = useGetPDPRedirectPath(data);

  const renderTitleAndPrice = () => {
    return (
      <Grid item xs={7} sm={12} md={10}>
        <Grid
          container
          spacing={processEnvServer ? (!mobile ? 3 : 2) : smUp ? 3 : 2}
          classes={{ root: localClasses?.priceRangeDisplay }}
        >
          <Grid item xs={12}>
            <RHLink
              onClick={() => {
                setRideAlongData?.(defaultRideAlongData);
                triggerAnalyticsEvent();
              }}
              data-testid={`productTitleLink-${data?.id}`}
              className="!uppercase hover:!text-gray-800 hover:!no-underline"
              to={redirectPath}
            >
              <Typography
                className={cn(
                  typographyStyles?.rhBaseH3,
                  "!uppercase hover:!text-gray-800 !hover:no-underline"
                )}
                dangerouslySetInnerHTML={{
                  __html: `${data?.displayName}${
                    data.deliveryDimensions
                      ? ` - ${data?.deliveryDimensions}`
                      : ""
                  }`
                }}
              />
              {data.subName && !data.subName.match(/(^|\W)cat($|\W)/i) && (
                <Typography
                  className={typographyStyles?.rhBaseCaption}
                  dangerouslySetInnerHTML={{
                    __html: data?.subName
                  }}
                />
              )}
            </RHLink>
          </Grid>

          <Grid item xs={12} style={{ paddingTop: 0 }}>
            {!pli?.customProductOptions?.customProductInformation
              ? selectedOptions?.length === pli.availableOptions?.length &&
                (metaSkuPricing || pli.sku?.info?.skuPriceInfo)
                ? renderLineItemPricing(PriceRangeType.RHRUniversalPriceRange)
                : renderLineItemPricing(PriceRangeType.RHPriceRange)
              : selectedOptions?.length === pli?.availableOptions?.length &&
                !loading &&
                isAllCustomOptionSelected &&
                isCustomOptionSelectionValid &&
                (metaSkuPricing || pli?.sku?.info?.skuPriceInfo)
              ? renderLineItemPricing(PriceRangeType.RHRUniversalPriceRange)
              : isCustomRug &&
                pli?.sku?.info &&
                !isDisabled &&
                renderLineItemPricing(PriceRangeType.RHPriceRange)}
          </Grid>
        </Grid>
        {isMultiSku(pli?.sku?.inventory?.fullSkuId) && data?.metaProduct && (
          <Hidden mdUp>
            {!yn(env.FEATURE_MULTISKU_PHASE_4) ||
            (yn(env.FEATURE_MULTISKU_PHASE_4_NA) && !isNorthAmerica) ? (
              <Grid container xs={12} className="!py-2.5 sm:!py-4">
                <Grid item xs={12} className="!bg-gray-100 !w-fit">
                  <Typography
                    className={cn(typographyStyles.rhBaseBody2, "px-1 py-4")}
                    dangerouslySetInnerHTML={{
                      __html: pageContent?.MULTISKU_INTERIM_COPY
                    }}
                  />
                </Grid>
              </Grid>
            ) : null}
          </Hidden>
        )}
      </Grid>
    );
  };
  const renderHeroImage = () => {
    return (
      <RHLink
        data-testid={`productImageLink-${data?.id}`}
        to={redirectPath}
        onClick={triggerAnalyticsEvent}
        className="!mb-2.5"
      >
        <RHImage
          src={productImageUrl ?? ""}
          alt={data?.displayName}
          key={productImageUrl ?? ""}
          objectFit={"contain"}
          preset="relatedPCard"
          aspectRatio={IMAGE_ASPECT_RATIO["thumbnail"]}
          imageWrapperStyles={{
            backgroundColor: theme?.palette?.background?.default
          }}
          imageSkeletonBackgroundColor="white"
          setIsLineItemImageUrlFailed={setIsLineItemImageUrlFailed}
          failedImageUrl={
            isLineItemImageUrlFailed
              ? (data?.productLineItem?.image?.imageUrl as string)
              : ""
          }
        />
      </RHLink>
    );
  };

  const renderLineItemPricing = (type: string) => {
    if (type === PriceRangeType.RHRUniversalPriceRange) {
      const price =
        metaSkuPricing && Object.keys(metaSkuPricing)?.length
          ? metaSkuPricing
          : pli?.sku?.info?.skuPriceInfo;
      return (
        <>
          <RHRPriceDisplay
            listPrice={price?.listPrice!}
            memberPrice={getPriceUserType(userType!, price)!}
            topLabel={pageContent?.CONFIGURES_PRICE}
            isSaleFilterEnabled={params?.sale === "true" ?? false}
            onSale={metaSkuPricing ? !!price?.salePrice : price?.onSale!}
            skulowestMemberPrice={getPriceUserType(userType!, price)!}
            showSaleMessage={false}
            userType={userType! || ""}
            pageContent={pageContent}
            variant={"medium"}
            centerAlignFlag={false}
            hideCss={true}
            showMembershipProductPrice={
              data?.uxAttributes?.membershipProduct?.toLowerCase() === "true"
            }
            showGiftCardPrice={
              data?.uxAttributes?.giftCert?.toLowerCase() === "true"
            }
          />
        </>
      );
    } else if (type === PriceRangeType.RHPriceRange) {
      return (
        <>
          <RHRPriceDisplay
            listPrice={data?.priceRangeDisplay?.listPrices?.[0]!}
            memberPrice={data?.priceRangeDisplay?.memberPrices?.[0]!}
            topLabel={
              data.priceRangeDisplay?.overridePriceLabel ||
              pageContent?.["STARTING_AT"]
            }
            isSaleFilterEnabled={params?.sale === "true" ?? false}
            onSale={data?.priceRangeDisplay?.nextGenDrivenOnSale!}
            skulowestMemberPrice={
              data?.priceRangeDisplay?.skulowestMemberPrice!
            }
            showSaleMessage={
              Number(data?.saleInfo?.percentSaleSkus) === 0 ||
              Number(data?.saleInfo?.percentSaleSkus) === 100
                ? false
                : true
            }
            userType={userType! || ""}
            pageContent={pageContent}
            computedSalePageContent={saleMessageForPriceComponent}
            variant={"medium"}
            centerAlignFlag={false}
            showMembershipProductPrice={
              data?.uxAttributes?.membershipProduct?.toLowerCase() === "true"
            }
            showGiftCardPrice={
              data?.uxAttributes?.giftCert?.toLowerCase() === "true"
            }
            hideCss={true}
          />
        </>
      );
    }
  };

  return (
    <>
      <Grid
        id={"component-related-product-card"}
        container
        spacing={inStockReady ? 0 : 4}
        className={`${inStockReady ? "!p-0" : ""} ${
          !heroImageLevel
            ? "!max-w-[1920px] xl:!px-[90px] !w-full !m-[0_auto]"
            : ""
        }`}
      >
        {processEnvServer && mobile ? (
          <Hidden>
            <Grid container spacing={2}>
              <Grid
                item
                sm={mobile ? 3 : 12}
                xs={mobile ? 5 : 12}
                md={inStockReady ? 12 : 2}
                className={`${
                  inStockReady && !heroImageLevel && mobile ? "hidden" : ""
                } ${!mobile ? "!py-4" : ""}`}
              >
                {inStockReady ? "" : renderHeroImage()}
                {!pli.sku?.restrictions?.serviceSku && !processEnvServer && (
                  <>
                    {!smDown && showInStockButton(heroImageLevel) && (
                      <RelatedProductInStockButton {...props} />
                    )}
                    {!!showSaleButton() &&
                      data?.saleInfo?.percentSaleSkus !== "100.000" &&
                      data?.saleInfo?.percentSaleSkus !== "0" &&
                      !inStockReady && (
                        <>
                          <br />
                          <RelatedProductOnSaleButton {...props} />
                        </>
                      )}
                  </>
                )}
              </Grid>
              {mobile && !inStockReady ? renderTitleAndPrice() : null}
            </Grid>
          </Hidden>
        ) : processEnvServer && !mobile ? (
          <Grid item xs md={inStockReady ? 12 : 2} className="!py-4 !px-0">
            {inStockReady ? "" : renderHeroImage()}
            {!pli?.sku?.restrictions?.serviceSku && (
              <>
                <>
                  {" "}
                  {showInStockButton(heroImageLevel) && (
                    <>
                      <RelatedProductInStockButton {...props} />
                    </>
                  )}
                </>

                {!!showSaleButton() &&
                  data?.saleInfo?.percentSaleSkus !== "100.000" &&
                  !inStockReady && (
                    <>
                      <br />
                      <RelatedProductOnSaleButton {...props} />
                    </>
                  )}
              </>
            )}
          </Grid>
        ) : (
          <Hidden>
            {smDown && !xsDown && !inStockReady ? renderTitleAndPrice() : null}{" "}
            <Grid
              item
              sm={3}
              xs={5}
              md={inStockReady ? 12 : 2}
              className={`${
                inStockReady && !heroImageLevel ? "sm:hidden" : ""
              } ${mdUp ? "md:!py-4" : ""} !px-0`}
            >
              {inStockReady ? "" : renderHeroImage()}
              {!pli.sku?.restrictions?.serviceSku && !processEnvServer && (
                <>
                  <>
                    {" "}
                    {!smDown && showInStockButton(heroImageLevel) && (
                      <RelatedProductInStockButton {...props} />
                    )}{" "}
                  </>
                  {!!showSaleButton() &&
                    data?.saleInfo?.percentSaleSkus !== "100.000" &&
                    !inStockReady && (
                      <>
                        <br />
                        <RelatedProductOnSaleButton {...props} />
                      </>
                    )}
                </>
              )}
            </Grid>
            {xsDown && !inStockReady ? renderTitleAndPrice() : null}
          </Hidden>
        )}
        {showInStockButton(heroImageLevel) && inStockReady && (
          <Hidden mdUp>
            <RHLinkListDrawer
              borderType={{ top: true }}
              title={inStockReady}
              dialogHeader={pageContent?.IN_STOCK}
              productId={data?.id}
            >
              <InStockDialogContent
                monogramOrder={monogramOrder}
                productId={data?.id}
                isRHR={data?.rhr}
                metaProduct={isMetaProduct}
                productName={data?.displayName}
                postalCode={pli?.sku?.delivery?.postalCode ?? postalCode}
                salePriceLabel={data?.priceRangeDisplay?.salePriceLabel ?? ""}
                productPrice={pli?.sku?.info?.skuPriceInfo}
                userType={userType || ""}
                multiSkuAdditionalMessage={
                  data?.multiSkuAdditionalMessage ?? ""
                }
              />
            </RHLinkListDrawer>
          </Hidden>
        )}
        {!inStockReady && (
          <Grid item xs={12} sm={9} md={10} lg={10}>
            <Grid container spacing={lgUp ? 4 : 2}>
              <Grid item xs={12}>
                <Grid container spacing={smUp ? 3 : 2}>
                  {/* <Hidden mdUp>
                    <Grid item xs={5}>
                      {renderHeroImage()}
                    </Grid>
                  </Hidden> */}
                  {mdUp && !mobile ? renderTitleAndPrice() : null}
                </Grid>
              </Grid>
              {isMultiSku(pli?.sku?.inventory?.fullSkuId) && data?.metaProduct && (
                <Hidden smDown>
                  {!yn(env.FEATURE_MULTISKU_PHASE_4) ||
                  (yn(env.FEATURE_MULTISKU_PHASE_4_NA) && !isNorthAmerica) ? (
                    <Grid
                      xs={12}
                      className={
                        data.merchMessage
                          ? "!px-2 lg:!px-4"
                          : "!px-2 !pb-2 lg:!px-4"
                      }
                    >
                      <Grid item xs={12} className="!bg-gray-100 !w-fit">
                        <Typography
                          className={cn(
                            typographyStyles.rhBaseBody2,
                            "!px-1 !py-4"
                          )}
                          dangerouslySetInnerHTML={{
                            __html: pageContent?.MULTISKU_INTERIM_COPY
                          }}
                        />
                      </Grid>
                    </Grid>
                  ) : null}
                </Hidden>
              )}
              {!!data?.merchMessage &&
                !(
                  userType === "CONTRACT" &&
                  data?.merchMessage?.includes("return")
                ) && (
                  <Grid item xs={12}>
                    <Grid item xs={12}>
                      <Typography
                        className={cn(typographyStyles.rhBaseCaption, "pl-1")}
                        dangerouslySetInnerHTML={{
                          __html: data?.merchMessage
                        }}
                      />
                    </Grid>
                  </Grid>
                )}
              {data?.displayName?.toLowerCase().includes("swatch") &&
                !!swatchShipMessage && (
                  <Grid item xs={12}>
                    <Grid item xs={12}>
                      <Typography
                        className={cn(typographyStyles.rhBaseCaption, "pl-1")}
                        dangerouslySetInnerHTML={{
                          __html: swatchShipMessage
                        }}
                      />
                    </Grid>
                  </Grid>
                )}

              {yn(env?.FEATURE_PDP_SKU_INFORMATION) && (
                <Grid item xs={12}>
                  <Typography className={typographyStyles?.rhBaseBody1}>
                    <RHLink
                      underline="always"
                      display="inline"
                      to={`/store-portal/product-details?productId=${data?.id}`}
                    >
                      SEE SKU INFORMATION
                    </RHLink>
                  </Typography>
                </Grid>
              )}

              <>
                <Grid item xs={12} sm={6} md={8}>
                  <Grid container spacing={2}>
                    {pli.availableOptions?.map((availableOption, index) => {
                      if (
                        data?.layout?.includes("custom-rug") &&
                        availableOption?.type === "Fabric"
                      ) {
                        return null;
                      }
                      let options = configureAvailableOptions(
                        availableOption?.options
                      );

                      options = options?.filter(
                        option => option?.status !== "unavailable"
                      );

                      if (availableOption?.type === "Color") {
                        options = sortBy(options, o => o?.value);
                      }

                      let optionLabel =
                        availableOption?.options?.find(option => option?.name)
                          ?.name || availableOption.type;

                      const selected =
                        options?.find(
                          option => option?.status === "selected"
                        ) || ({} as ProductAvailableOption);
                      return (
                        <>
                          <Grid
                            key={`availableOption-${index}`}
                            item
                            xs={12}
                            md={6}
                          >
                            <ProductOptionDropdown
                              loading={loading}
                              id={`optionSelect-${data?.id}-${availableOption?.type}`}
                              label={optionLabel}
                              disabled={
                                options?.length < 1 &&
                                selected?.status === "selected"
                              }
                              options={options?.map(option => ({
                                key: `${availableOption?.type}-${option?.id}`,
                                value: option?.id,
                                display: he?.decode(option?.value)
                              }))}
                              value={selected?.id || ""}
                              onChange={value => {
                                onProductConfigChange(value, options);
                                // if (setIsSwatchImageUpdated)
                                //   setIsSwatchImageUpdated(false);
                              }}
                              fullWidth
                            />
                          </Grid>
                          {pli?.sku?.info?.name?.includes("Gift Card") &&
                            env?.FEATURE_GIFT_CARD_TO_FROM_FIELDS && (
                              <>
                                <Grid
                                  key={`giftCardTo-${index}`}
                                  item
                                  xs={12}
                                  md={6}
                                >
                                  <TextField
                                    id="pdp-gift-card-to"
                                    className="!mt-0"
                                    label="To"
                                    variant="outlined"
                                    type="text"
                                    fullWidth={true}
                                    value={giftCardTo}
                                    onChange={(
                                      event: React.ChangeEvent<HTMLInputElement>
                                    ) =>
                                      setGiftCardTo(
                                        event?.target?.value?.substring(0, 45)
                                      )
                                    }
                                  />
                                </Grid>
                                <Grid
                                  key={`giftCardFrom-${index}`}
                                  item
                                  xs={12}
                                  md={6}
                                >
                                  <TextField
                                    id="pdp-gift-card-from"
                                    className="!mt-0"
                                    label="From"
                                    variant="outlined"
                                    type="text"
                                    fullWidth={true}
                                    value={giftCardFrom}
                                    onChange={(
                                      event: React.ChangeEvent<HTMLInputElement>
                                    ) =>
                                      setGiftCardFrom(
                                        event?.target?.value?.substring(0, 45)
                                      )
                                    }
                                  />
                                </Grid>
                              </>
                            )}
                        </>
                      );
                    })}

                    {pli?.customProductOptions?.customProductInformation
                      ?.rugTrims ? (
                      <>
                        <Grid item xs={12} md={6}>
                          <ProductOptionDropdown
                            loading={loading}
                            id="rug-trim"
                            label="Trim Color"
                            options={sortBy(
                              pli?.customProductOptions?.customProductInformation?.rugTrims?.filter(
                                option => option?.status === "available"
                              ),
                              o => o?.value
                            )?.map(option => ({
                              key: `rug-trim-${option?.id}`,
                              value: option?.id || "",
                              display: he.decode(option?.value || "")
                            }))}
                            value={rugTrim || ""}
                            onChange={onRugConfigChange}
                            fullWidth
                          />
                        </Grid>
                      </>
                    ) : null}

                    {pli?.customProductOptions?.customProductInformation
                      ?.shape === "round" ? (
                      <Grid item xs={12} md={6}>
                        <ProductOptionDropdown
                          loading={loading}
                          id={`${data?.id}-diameter-feet-input`}
                          label="Diameter (Feet)"
                          options={Array.from(
                            {
                              length:
                                Number(
                                  (pli?.customProductOptions
                                    ?.customProductInformation?.maxDiameter ||
                                    0) -
                                    (pli?.customProductOptions
                                      ?.customProductInformation?.minDiameter ||
                                      0)
                                ) + 1
                            },
                            (_, i) =>
                              (pli?.customProductOptions
                                ?.customProductInformation?.minDiameter || 0) +
                              i
                          ).map(item => ({
                            key: `diameter-feet-${item}`,
                            value: `${item}`,
                            display: he.decode(`${item}&#8242;`)
                          }))}
                          value={customOptions?.diameter?.feet || ""}
                          onChange={handleDiameterInFeetChange}
                          fullWidth
                        />
                      </Grid>
                    ) : null}
                    {pli?.customProductOptions?.customProductInformation
                      ?.shape === "round" ? (
                      <Grid item xs={12} md={6}>
                        <ProductOptionDropdown
                          loading={loading}
                          id={`${data.id}-diameter-inches-input`}
                          label="Diameter (Inches)"
                          options={Array.from(
                            {
                              length: 11
                            },
                            (_, i) => 1 + i
                          ).map(item => ({
                            key: `diameter-inches-${item}`,
                            value: `${item}`,
                            display: he?.decode(`${item} &#8243;`)
                          }))}
                          value={customOptions?.diameter?.inches || ""}
                          onChange={handleDiameterInInchesChange}
                          // helperText={
                          //   !pli?.sku &&
                          //   isAllCustomOptionSelected &&
                          //   pli?.availableOptions?.length ===
                          //     selectedOptions.length ? (
                          //     <Typography color="error" component="span">
                          //       One or more of the options selected is no longer
                          //       available.
                          //     </Typography>
                          //   ) : (
                          //     ""
                          //   )
                          // }
                          fullWidth
                        />
                      </Grid>
                    ) : null}
                    {pli?.customProductOptions?.customProductInformation
                      ?.shape === "rectangle" ? (
                      <Grid item xs={12} md={6}>
                        <ProductOptionDropdown
                          loading={loading}
                          id={`${data?.id}-width-feet-input`}
                          label="Width (Feet)"
                          options={Array?.from(
                            {
                              length:
                                Number(
                                  (pli?.customProductOptions
                                    ?.customProductInformation?.maxWidth || 0) -
                                    (pli?.customProductOptions
                                      ?.customProductInformation?.minWidth || 0)
                                ) + 1
                            },
                            (_, i) =>
                              (pli?.customProductOptions
                                ?.customProductInformation?.minWidth || 0) + i
                          )?.map(item => ({
                            key: `width-feet-${item}`,
                            value: `${item}`,
                            display: he.decode(`${item}&#8242;`)
                          }))}
                          value={customOptions?.width?.feet || ""}
                          onChange={handleWidthInFeetChange}
                          fullWidth
                        />
                      </Grid>
                    ) : null}
                    {pli?.customProductOptions?.customProductInformation
                      ?.shape === "rectangle" ? (
                      <Grid item xs={12} md={6}>
                        <ProductOptionDropdown
                          loading={loading}
                          id={`${data?.id}-width-inches-input`}
                          label="Width (Inches)"
                          options={Array?.from(
                            {
                              length: 11
                            },
                            (_, i) => 1 + i
                          )?.map(item => ({
                            key: `width-inches-${item}`,
                            value: `${item}`,
                            display: he?.decode(`${item}&#8243;`)
                          }))}
                          value={customOptions?.width?.inches || ""}
                          onChange={handleWidthInInchesChange}
                          fullWidth
                        />
                      </Grid>
                    ) : null}
                    {pli?.customProductOptions?.customProductInformation
                      ?.shape === "rectangle" ? (
                      <Grid item xs={12} md={6}>
                        <ProductOptionDropdown
                          loading={loading}
                          id={`${data.id}-length-feet-input`}
                          label="Length (Feet)"
                          options={Array.from(
                            {
                              length:
                                Number(
                                  (pli?.customProductOptions
                                    ?.customProductInformation?.maxLength ||
                                    0) -
                                    (pli?.customProductOptions
                                      ?.customProductInformation?.minLength ||
                                      0)
                                ) + 1
                            },
                            (_, i) =>
                              (pli?.customProductOptions
                                ?.customProductInformation?.minLength || 0) + i
                          )?.map(item => ({
                            key: `length-feet-${item}`,
                            value: `${item}`,
                            display: he?.decode(`${item}&#8242;`)
                          }))}
                          value={customOptions?.length?.feet || ""}
                          onChange={handleLengthInFeetChange}
                          fullWidth
                        />
                      </Grid>
                    ) : null}

                    {pli?.customProductOptions?.customProductInformation
                      ?.shape === "rectangle" ? (
                      <Grid item xs={12} md={6}>
                        <ProductOptionDropdown
                          loading={loading}
                          id={`${data?.id}-length-inches-input`}
                          label="Length (Inches)"
                          options={Array.from(
                            {
                              length: 11
                            },
                            (_, i) => 1 + i
                          )?.map(item => ({
                            key: `length-inches-${item}`,
                            value: `${item}`,
                            display: `${item}′′ `
                          }))}
                          value={customOptions?.length?.inches || ""}
                          onChange={handleLengthInchesChange}
                          fullWidth
                        />
                      </Grid>
                    ) : null}
                    {!(pli?.customProductOptions?.customProductInformation
                      ? isAllCustomOptionSelected
                        ? isDisabled
                        : true
                      : isDisabled) &&
                      pli?.sku?.delivery?.shippingSurcharge && (
                        <Grid item xs={12}>
                          <ButtonBase
                            className="!text-[11px] !cursor-pointer !underline"
                            onClick={() => setSurchargeInfoDialog(true)}
                          >
                            +{" "}
                            {pageContent?.SHIPPING_SURCHARGE ??
                              SHIPPING_SURCHARGE}{" "}
                            {pli?.sku?.delivery?.shippingSurchargeAmount} ea
                          </ButtonBase>
                        </Grid>
                      )}

                    {yn(env?.FEATURE_DESKTOP_MONOGRAM) &&
                      monogrammable &&
                      !checkCountryIsEu({ country }) && (
                        <Grid item xs={12}>
                          <MonogramChoice
                            fee={data?.personalizeInfo.feeHigh}
                            currencyLabel={
                              data?.priceRangeDisplay?.currencyLabel || ""
                            }
                            currencySymbol={
                              data?.priceRangeDisplay?.currencySymbol || ""
                            }
                            monogramOrder={monogramOrder}
                            openMonogramDialog={setMonogramDialog}
                            removeMonogram={() =>
                              setMonogramOrder(MONOGRAM_ORDER_DEFAULT)
                            }
                          />
                        </Grid>
                      )}
                    <Hidden smDown>
                      {isConcierge &&
                      showItemNumber &&
                      isCustomOptionSelectionValid &&
                      params?.prevFullSkuId?.split(" ")?.[0] ===
                        params?.fullSkuId?.split(" ")?.[0] &&
                      params?.prevFullSkuId?.split(" ")?.[1] !==
                        params?.fullSkuId?.split(" ")?.[1] ? (
                        <>
                          <Grid item xs={12}>
                            <Typography className="text-red-600">
                              {pageContent?.THE_COLOR_CODE_YOU_ENTERED}{" "}
                              {params?.prevFullSkuId?.split(" ")?.[1]}{" "}
                              {pageContent?.IS_INVALID_OR_NO_LONGER_AVAILBLE}
                            </Typography>
                          </Grid>
                        </>
                      ) : (
                        !pli?.sku?.info?.webPurchasable ||
                        (pli?.sku?.inventory?.inventoryRemaining?.length ===
                          0 && (
                          <>
                            <Grid item xs={12}>
                              <Typography className="text-red-600">
                                {
                                  pageContent?.THE_COLOR_CODE_YOU_ENTERED_IS_NO_LONGER_AVAILABLE
                                }
                              </Typography>
                            </Grid>
                          </>
                        ))
                      )}
                      <Grid
                        item
                        xs={12}
                        className={`${
                          isNewPDPLayout
                            ? localClasses.relatedProductSkuContainer
                            : " "
                        } ${processEnvServer ? "!p-2" : ""}`}
                      >
                        <Sku
                          isDisabled={isDisabled}
                          inStock={data?.instock?.hasInStock}
                          skuInventoryMessage={
                            isMultiSku(pli?.sku?.inventory?.fullSkuId) &&
                            data?.metaProduct === true
                              ? multiSkuComponentsDataV2?.skuInventory
                                  ?.lineItemMessage || ""
                              : skuInventoryMessage
                          }
                          hideRestrictions={
                            (!showItemNumber && !skuDetails?.fullSkuId) ||
                            (monogrammable &&
                              monogramOrder?.lines?.length === 0)
                          }
                          availabilityStatus={
                            skuAvailabilityStatus?.availabilityStatus
                          }
                          sku={
                            isMultiSku(pli?.sku?.inventory?.fullSkuId) &&
                            data?.metaProduct === true
                              ? {
                                  ...pli?.sku,
                                  inventory:
                                    multiSkuComponentsDataV2?.skuInventory ??
                                    {},

                                  ...(FEATURE_PDP_CART_DELIVERY
                                    ? {}
                                    : {
                                        delivery:
                                          multiSkuComponentsDataV2?.multiSkuComponentsDeliveryInfo
                                      })
                                }
                              : pli?.sku
                          }
                          onPostalCode={() => setPostalCodeDialog(true)}
                          showSPOmsg={!isDisabled || !!skuDetails?.fullSkuId}
                          giftCardValueExceed={giftCardValueExceed}
                          isMultiSku={
                            isMultiSku(pli?.sku?.inventory?.fullSkuId) &&
                            data?.metaProduct === true
                          }
                          multiSkuSPOReturnMessage={
                            multiSkuComponentsDataV2?.components?.[0]
                              ?.restrictions?.returnPolicyMessage
                          }
                          multiSkuCountryRestrictions={
                            multiSkuComponentsDataV2?.components?.[0]
                              ?.restrictions?.countryRestrictions!
                          }
                          spoOverrideMessage={
                            spoOverrideMessage &&
                            multiSkuComponentsDataV2?.isSPO
                              ? spoOverrideMessage
                              : undefined
                          }
                          isNewPDPLayout={isNewPDPLayout}
                          loading={loading}
                        />
                        {skuDetails?.fullSkuId && (
                          <>
                            <Typography
                              className={cn(
                                typographyStyles.rhBaseCaption,
                                "!flex"
                              )}
                              data-testid={"item-sku-id-desktop"}
                            >
                              <p> {pageContent?.ITEM}</p>&nbsp;
                              <p>{skuDetails?.fullSkuId}</p>
                            </Typography>
                          </>
                        )}
                      </Grid>
                      {yn(env?.FEATURE_PDP_REPLACEMENT_PARTS) &&
                        pli?.availableOptions?.length ===
                          selectedOptions?.length &&
                        pli?.sku?.inventory?.fullSkuId && (
                          <Grid item xs={12}>
                            <ReplacementPartButton
                              postalCode={
                                pli?.sku?.delivery?.postalCode ?? postalCode
                              }
                            >
                              <ReplacementPartDialogContent
                                productId={data?.id}
                                fullSkuId={pli?.sku?.inventory?.fullSkuId}
                                name={pli?.sku?.info?.name || ""}
                                postalCode={
                                  pli?.sku?.delivery?.postalCode ?? postalCode
                                }
                                monogramOrder={monogramOrder}
                                salePriceLabel={
                                  data?.priceRangeDisplay?.salePriceLabel ?? ""
                                }
                              />
                            </ReplacementPartButton>
                          </Grid>
                        )}
                    </Hidden>
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  className="lg:!pl-0 !pl-2 !pr-2"
                >
                  <Grid container spacing={lgUp ? 0 : 2}>
                    <Grid item xs={5} md={4}>
                      <div className="sm:block flex flex-row">
                        <ProductOptionDropdown
                          className="md:!pr-4"
                          loading={loading}
                          id={`${data?.id}-qty-input`}
                          label={pageContent?.QTY}
                          options={Array.from({
                            length: maxOrderQty + 1
                          }).map((item, index) => ({
                            key: `qty-${index}`,
                            value: `${index}`,
                            display: index
                          }))}
                          value={qty?.toString()}
                          onChange={value => {
                            setQty(Number(value));
                            setIsQtyChanged(true);
                          }}
                          fullWidth
                        />
                        {!!pli?.sku?.fulfillmentEta?.itemsInStockMessage &&
                          !isDisabled && (
                            <div className="flex items-center whitespace-nowrap justify-end md:pt-[15px] md:pr-5">
                              <Typography
                                className={typographyStyles?.rhBaseCaption}
                              >
                                {pli?.sku?.fulfillmentEta?.itemsInStockMessage}
                              </Typography>
                            </div>
                          )}
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={7} md={8}>
                      <RelatedProductActions
                        isDisabled={
                          !skuDetails?.fullSkuId ||
                          ((multiSkuComponentsDataV2?.components || []).length >
                          0
                            ? addOnsExist && selectedAddOnsNotFullyConfigured
                              ? true
                              : false
                            : pli?.customProductOptions
                                ?.customProductInformation
                            ? !(
                                showItemNumber &&
                                isCustomOptionSelectionValid &&
                                skuDetails?.fullSkuId &&
                                isEnableCustomRug
                              )
                            : isDisabled)
                        }
                        qty={qty}
                        selectedIds={selectedIds?.current}
                        displayName={data?.displayName}
                        isAuthenticated={authenticated}
                        hasInventory={!!pli?.sku?.inventory}
                        isPreOrder={pli?.sku?.inventory?.preOrder || false}
                        selectedQuantity={qty}
                        productLineItem={pli}
                        handleAddToCartClick={handleAddToCartClick}
                        handleAddToWishlistClick={handleAddToWishlistClick}
                        handleAddToGiftRegistryClick={
                          handleAddToGiftRegistryClick
                        }
                        customInfo={customProductConfig}
                        monogramOrder={monogramOrder}
                        options={customSelectedOptions}
                        productAddons={addonDataAddToProject}
                        productAddonsInfo={
                          data?.productAddons?.productAddonsInfo
                        }
                        multiSkuComponentsDataV2={
                          multiSkuComponentsDataV2?.components
                        }
                        multiSkuComponents={
                          multiSkuComponentsDataV2?.components
                        }
                        productDetails={productDetails}
                        fullSkuId={skuDetails?.fullSkuId ?? ""}
                      />
                      {!!pli?.sku?.fulfillmentEta?.itemsInStockMessage &&
                        !isDisabled && (
                          <div className="flex items-center whitespace-nowrap justify-end md:pt-[15px] md:pr-5">
                            <Typography
                              className={cn(
                                typographyStyles?.rhBaseCaption,
                                "!uppercase"
                              )}
                            >
                              {pli?.sku?.fulfillmentEta?.itemsInStockMessage}
                            </Typography>
                          </div>
                        )}
                    </Grid>
                  </Grid>
                </Grid>
                {addOnsExist &&
                  shouldDisplayAddOns &&
                  data?.id === props?.productId && (
                    <>
                      {data?.productAddons?.productAddonsInfo?.map(item => {
                        const addOnData = addOnsData?.find(
                          prod => prod?.productId === item?.id
                        );
                        return (
                          <Grid item xs={12} md={12}>
                            <AddOnCheckBox
                              data={item}
                              opts={item?.productLineItem?.availableOptions}
                              imgURL={item?.productLineItem?.image?.imageUrl}
                              loading={loading}
                              mainProductQty={qty}
                              handleAddonData={handleAddonData}
                              addOnData={addOnData}
                              productLineItemOptions={
                                lineItem?.availableOptions as ProductAvailableOptionSet[]
                              }
                            />
                          </Grid>
                        );
                      })}
                    </>
                  )}
                <Hidden mdUp>
                  {isConcierge &&
                  showItemNumber &&
                  isCustomOptionSelectionValid &&
                  params?.prevFullSkuId?.split(" ")?.[0] ===
                    params?.fullSkuId?.split(" ")?.[0] &&
                  params?.prevFullSkuId?.split(" ")?.[1] !==
                    params?.fullSkuId?.split(" ")?.[1] ? (
                    <>
                      <Grid item xs={12}>
                        <Typography className="!text-red-600">
                          {pageContent?.THE_COLOR_CODE_YOU_ENTERED}{" "}
                          {params?.prevFullSkuId.split(" ")?.[1]}{" "}
                          {pageContent?.IS_INVALID_OR_NO_LONGER_AVAILBLE}
                        </Typography>
                      </Grid>
                    </>
                  ) : (
                    !pli?.sku?.info?.webPurchasable ||
                    (pli?.sku?.inventory?.inventoryRemaining?.length === 0 && (
                      <>
                        <Grid item xs={12}>
                          <Typography className="!text-red-600">
                            {
                              pageContent?.THE_COLOR_CODE_YOU_ENTERED_IS_NO_LONGER_AVAILABLE
                            }
                          </Typography>
                        </Grid>
                      </>
                    ))
                  )}
                  {giftCardValueExceed && (
                    <Typography
                      component="span"
                      className="!text-red-300 !pl-2"
                    >
                      {pageContent?.GIFTCARD_LIMIT}
                    </Typography>
                  )}
                  <Grid item xs={12}>
                    {pli?.sku && (
                      <RHLinkListCollapse
                        borderType={{ top: true }}
                        title={pageContent?.availabilityDeliveryReturns}
                      >
                        <Sku
                          isDisabled={isDisabled}
                          skuInventoryMessage={
                            isMultiSku(pli?.sku?.inventory?.fullSkuId) &&
                            data?.metaProduct === true
                              ? multiSkuComponentsDataV2?.skuInventory
                                  ?.lineItemMessage
                              : skuInventoryMessage
                          }
                          availabilityStatus={
                            skuAvailabilityStatus?.availabilityStatus
                          }
                          sku={
                            isMultiSku(pli?.sku?.inventory?.fullSkuId) &&
                            data?.metaProduct === true
                              ? {
                                  ...pli?.sku,
                                  inventory:
                                    multiSkuComponentsDataV2?.skuInventory ??
                                    {},
                                  ...(FEATURE_PDP_CART_DELIVERY
                                    ? {}
                                    : {
                                        delivery:
                                          multiSkuComponentsDataV2?.multiSkuComponentsDeliveryInfo
                                      })
                                }
                              : pli?.sku
                          }
                          hideRestrictions={
                            (!showItemNumber && !skuDetails?.fullSkuId) ||
                            (monogrammable &&
                              monogramOrder?.lines?.length === 0)
                          }
                          onPostalCode={() => setPostalCodeDialog(true)}
                          showSPOmsg={!isDisabled || !!skuDetails?.fullSkuId}
                          isMultiSku={
                            isMultiSku(pli?.sku?.inventory?.fullSkuId) &&
                            data?.metaProduct === true
                          }
                          multiSkuSPOReturnMessage={
                            multiSkuComponentsDataV2?.components?.[0]
                              ?.restrictions?.returnPolicyMessage
                          }
                          multiSkuCountryRestrictions={
                            multiSkuComponentsDataV2?.components?.[0]
                              ?.restrictions?.countryRestrictions!
                          }
                          spoOverrideMessage={
                            isMultiSku(pli?.sku?.inventory?.fullSkuId) &&
                            spoOverrideMessage &&
                            multiSkuComponentsDataV2?.isSPO
                              ? spoOverrideMessage
                              : undefined
                          }
                        />
                        <Typography
                          className={typographyStyles?.rhBaseCaption}
                          data-testid={"item-sku-id-mobile"}
                        >
                          {`${pageContent?.ITEM} ${pli?.sku?.inventory?.fullSkuId}`}
                        </Typography>
                      </RHLinkListCollapse>
                    )}
                    {!pli?.sku?.restrictions?.serviceSku &&
                      (data?.instock?.hasInStock || data?.sale?.hasSale) && (
                        <>
                          {showInStockButton(heroImageLevel) &&
                            !isShellProduct &&
                            swatch === "false" && (
                              <RHLinkListDrawer
                                borderType={{ top: true }}
                                title={pageContent?.IN_STOCK}
                                dialogHeader={pageContent?.IN_STOCK}
                                productId={data?.id}
                              >
                                <InStockDialogContent
                                  monogramOrder={monogramOrder}
                                  productId={data?.id}
                                  metaProduct={isMetaProduct}
                                  productName={data?.displayName}
                                  postalCode={
                                    pli?.sku?.delivery?.postalCode ?? postalCode
                                  }
                                  salePriceLabel={
                                    data?.priceRangeDisplay?.salePriceLabel ??
                                    ""
                                  }
                                  productPrice={pli?.sku?.info?.skuPriceInfo}
                                  userType={userType || ""}
                                  multiSkuAdditionalMessage={
                                    data?.multiSkuAdditionalMessage ?? ""
                                  }
                                />
                              </RHLinkListDrawer>
                            )}
                          {showSaleButton() && (
                            <RHLinkListDrawer
                              borderType={{ top: true }}
                              title={pageContent.ON_SALE ?? ON_SALE}
                              dialogHeader={`<span class="text-red-600">${
                                pageContent.ON_SALE ?? ON_SALE
                              }</span>`}
                              className="!text-red-600"
                            >
                              <OnSaleDialogContent
                                monogramOrder={monogramOrder}
                                productId={data?.id}
                                isRHR={data?.rhr}
                                metaProduct={isMetaProduct}
                                productName={data?.displayName}
                                postalCode={
                                  pli?.sku?.delivery?.postalCode ?? postalCode
                                }
                                salePriceLabel={
                                  data?.priceRangeDisplay?.salePriceLabel ?? ""
                                }
                                productPrice={pli?.sku?.info?.skuPriceInfo}
                                userType={userType || ""}
                                multiSkuAdditionalMessage={
                                  data?.multiSkuAdditionalMessage ?? ""
                                }
                              />
                            </RHLinkListDrawer>
                          )}
                          {yn(env?.FEATURE_PDP_REPLACEMENT_PARTS) &&
                            pli?.availableOptions?.length ===
                              selectedOptions.length &&
                            pli?.sku?.inventory?.fullSkuId && (
                              <RHLinkListDrawer
                                borderType={{ top: true }}
                                title={pageContent.CHECK_REPLACEMENT_PARTS}
                                dialogHeader={pageContent.ON_SALE}
                              >
                                <ReplacementPartDialogContent
                                  productId={data?.id}
                                  fullSkuId={pli?.sku?.inventory?.fullSkuId}
                                  name={pli?.sku?.info?.name || ""}
                                  postalCode={
                                    pli?.sku?.delivery?.postalCode ?? postalCode
                                  }
                                  monogramOrder={monogramOrder}
                                  salePriceLabel={
                                    data?.priceRangeDisplay?.salePriceLabel ??
                                    ""
                                  }
                                />
                              </RHLinkListDrawer>
                            )}
                        </>
                      )}
                    <Divider />
                  </Grid>
                </Hidden>
              </>
            </Grid>
          </Grid>
        )}
      </Grid>
      {postalCodeDialog ? (
        internationalFlag ? (
          <InternationalPostalCodeDialog
            open={postalCodeDialog}
            style={{ paddingBottom: smUp ? "0" : "60%" }}
            onClose={() => setPostalCodeDialog(false)}
          />
        ) : (
          <PostalCodeDialog
            open={postalCodeDialog}
            style={{ paddingBottom: smUp ? "0" : "60%" }}
            onClose={() => setPostalCodeDialog(false)}
          />
        )
      ) : null}
      {surchargeInfoDialog && (
        <SurchargeInfoDialog
          open={surchargeInfoDialog}
          onClose={() => setSurchargeInfoDialog(false)}
        />
      )}
      {cartDialog && (
        <>
          <AddToCartDialog
            monogramOrder={monogramOrder}
            open={cartDialog}
            onClose={() => handleCloseAddToCartDialog()}
            productId={data?.id}
            fullSkuId={skuDetails?.fullSkuId ?? ""}
            atgSkuId={pli?.sku?.inventory?.atgSkuId ?? ""}
            multiSkuComponentsDataV2={multiSkuComponentsDataV2}
            spo={
              isMultiSku(pli?.sku?.inventory?.fullSkuId) &&
              data?.metaProduct === true
                ? multiSkuComponentsDataV2?.isSPO
                : pli?.sku?.restrictions?.spo
            }
            productDisplayName={data?.displayName}
            onCompleted={cartDetails => {
              const pricing = pli?.sku?.info?.skuPriceInfo;
              if (!processEnvServer) {
                analyticsLoader(a =>
                  a?.emitAnalyticsEvent(
                    document?.querySelector<HTMLInputElement>("#spa-root > *")!,
                    a?.EVENTS?.GA4_ADD_TO_CART?.INT_TYPE,
                    {
                      cartdetails: {
                        cartId: cartDetails?.id,
                        cartCreationDate: cartDetails?.createdAt,
                        cartUpdationDate: cartDetails?.lastModifiedAt,
                        currency: cartDetails?.cartPrice?.currencyCode
                      },
                      item: {
                        name: data?.displayName,
                        id: data?.id,
                        quantity: qty,
                        sku: skuDetails?.fullSkuId ?? "",
                        pricing:
                          (pli?.sku?.info?.skuPriceInfo?.listPrice || 0) * qty,
                        currencyCode: cartDetails?.cartPrice?.currencyCode,
                        color: pli?.availableOptions
                          ?.find(opt => opt?.type?.includes("Color"))
                          ?.options?.find(opt => opt?.status === "selected")
                          ?.value
                      },
                      store_number: "Website",
                      item_list_name: "RelatedProductCard"
                    }
                  )
                );
              }
            }}
            preBillMessage={
              multiSkuComponentsDataV2?.isSPO
                ? multiSkuComponentsDataV2?.multiSkuSpoPreBillMessage
                : pli?.sku?.restrictions?.preBillMessage
                ? pli?.sku?.restrictions?.preBillMessage
                : pli?.sku?.restrictions?.returnPolicyMessage || ""
            }
            options={customSelectedOptions}
            qty={qty}
            giftCardTo={giftCardTo}
            giftCardFrom={giftCardFrom}
            salePriceLabel={data?.priceRangeDisplay?.salePriceLabel || ""}
            pricing={
              !isEmpty(multiSkuComponentsDataV2)
                ? metaSkuPricing
                : pli?.sku?.info?.skuPriceInfo
            }
            customInfo={customProductConfig}
            setConfirmed={setConfirmed}
            confirmed={confirmed}
            productAddons={addonDataAddToCart}
            productAddonsInfo={data?.productAddons?.productAddonsInfo}
            multiSkuComponents={skuDetails?.components}
            onAddOnConfigChange={onAddOnConfigChange}
            panelProdData={panelProdData}
            panelProdConfigurationData={panelProdConfigurationData}
            productAddonDisplayOptions={data?.productAddonDisplayOptions || ""}
            productLineItemOptions={
              lineItem?.availableOptions as ProductAvailableOptionSet[]
            }
            incomingPanelProdConfigData={loading ?? false}
            addToCartItemDetails={addToCartItemDetails}
          />
        </>
      )}
      {wishlistDialog && country !== "GB" && (
        <AddToWishlistDialog
          open={wishlistDialog}
          onClose={() => setWishlistDialog(false)}
          productId={data?.id}
          fullSkuId={skuDetails?.fullSkuId ?? ""}
          atgSkuId={pli?.sku?.inventory?.atgSkuId ?? ""}
          qty={qty}
          customInfo={customProductConfig}
          monogramOrder={monogramOrder}
          multiSkuComponents={multiSkuComponentsDataV2?.components}
        />
      )}

      {giftRegistryDialog && country !== "GB" && (
        <>
          <AddToGiftRegistryDialog
            open={giftRegistryDialog}
            onClose={() => setGiftRegistryDialog(false)}
            productId={data?.id}
            fullSkuId={skuDetails?.fullSkuId ?? ""}
            atgSkuId={pli?.sku?.inventory?.atgSkuId ?? ""}
            qty={qty}
            name={data?.displayName}
            imageUrl={productImageUrl ?? data?.productLineItem?.image?.imageUrl}
            options={selectedOptions}
            monogramOrder={monogramOrder}
            customInfo={customProductConfig}
            multiSkuComponents={multiSkuComponentsDataV2?.components}
          />
        </>
      )}
      {monogramDialog && country !== "GB" && (
        <MonogramDialog
          monogramOrder={monogramOrder}
          open={monogramDialog}
          productId={data?.id}
          setMonogramDialog={setMonogramDialog}
          setMonogramOrder={setMonogramOrder}
        />
      )}
      {lineItemIndex === 0 && (
        <>
          <PrintablePDPTemplate
            data={data}
            pli={pli}
            qty={qty}
            selectedHeroImageSwatchUrl={
              data?.productLineItem?.image?.imageUrl ?? ""
            }
            fullSkuId={
              lineItem?.sku?.inventory?.fullSkuId
                ? processEnvServer
                  ? getLocationSearch()
                  : `${
                      location?.pathname + location?.search
                    }&fullSkuId=${encodeURI(
                      lineItem?.sku?.inventory?.fullSkuId
                    )}`
                : processEnvServer
                ? getLocationSearch()
                : `${location?.pathname + location?.search}`
            }
            priceConfiguration={{
              metaSkuPricing: metaSkuPricing,
              country: country,
              userType: userType,
              selectedOptionLength: selectedOptions?.length
            }}
            swatchGroups={swatchGroups}
            productName={data?.displayName}
            selectedOptions={selectedOptions}
          />
        </>
      )}
    </>
  );
};
RelatedProductCard.defaultProps = {};

const RPC = memoize(RelatedProductCard);

export default RPC;
