import React, { FC, ReactElement } from "react";
import { Grid, Button, FormControl } from "utils/material-ui-core";
import memoize from "utils/memoize";

export interface EReplacementPartProductCardActionsProps {
  isDisabled;
  replacementPart: ReplacementPartItem;
  qty: number;
  productId: string;
  productDisplayName: string;
  salePriceLabel: string;
  handleAddToCartClick: () => void;
  extraFormFragments?: [ReactElement];
  [key: string]: any;
}

export const EReplacementPartProductCardActions: FC<
  EReplacementPartProductCardActionsProps
> = ({
  isDisabled,
  qty,
  productId,
  productDisplayName,
  salePriceLabel,
  handleAddToCartClick,
  extraFormFragments,
  ...rest
}) => {
  return (
    <Grid container justify="space-between" alignItems="center" spacing={2}>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddToCartClick}
            disabled={isDisabled}
            fullWidth
            data-testid="add-to-cart-dialog-opener"
          >
            ADD TO CART
          </Button>
        </FormControl>
      </Grid>
      {!!extraFormFragments &&
        extraFormFragments.map((form, index) => (
          <React.Fragment key={index}>{form}</React.Fragment>
        ))}
    </Grid>
  );
};

EReplacementPartProductCardActions.defaultProps = {};
export default memoize(EReplacementPartProductCardActions);
