import React, { FC, useCallback } from "react";
import { Typography, Grid, useTheme } from "utils/material-ui-core";
import AddToCartDialog from "dialog-add-to-cart";
import useState from "hooks/useState";
import OptionsDetailsList from "component-options-details-list";
import analyticsLoader from "analytics/loader";
import { MonogramOrderProps } from "page-monogram-dialog";
import { ReplacementPartProductCardActions } from "component-replacement-part-product-actions";
import RHStandardSelect from "component-rh-standard-select";
import ProductSkuPriceInfo from "component-product-sku-price-info";
import memoize from "utils/memoize";
import useTypographyStyles from "hooks/useTypographyStyles";

export interface ReplacementPartProductCardProps {
  data: ReplacementPartItem;
  productId: string;
  productDisplayName: string;
  salePriceLabel: string;
  monogramOrder: MonogramOrderProps;
}

const ReplacementPartProductCard: FC<ReplacementPartProductCardProps> = ({
  data,
  productId,
  productDisplayName,
  salePriceLabel,
  monogramOrder
}) => {
  const theme = useTheme();
  const [qty, setQty] = useState<number>(0);

  const [cartDialog, setCartDialog] = useState<boolean>(false);

  const handleQuantityChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      setQty(Number(event?.target?.value));
    },
    [qty, setQty]
  );

  const handleAddToCartClick = useCallback(() => {
    setCartDialog(true);
  }, []);

  const handleAddToCartDialogClose = useCallback(() => {
    setCartDialog(false);
  }, []);

  const typographyStyles = useTypographyStyles({
    keys: ["rhBaseCaption"]
  });

  return (
    <div
      id={"component-replacement-part-product-card"}
      style={{
        overflow: "hidden"
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <OptionsDetailsList
                itemId={data?.fullSkuId}
                options={data?.optionList}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4}>
              {data?.inventoryMessage && (
                <Typography
                  className={typographyStyles.rhBaseCaption}
                  style={{
                    fontSize: "13px",
                    textTransform: "uppercase"
                  }}
                >
                  {data?.itemDescription}
                </Typography>
              )}
            </Grid>
            <Grid item xs={4} sm={2} md={2}>
              <ProductSkuPriceInfo
                data={data?.skuPriceInfo}
                salePriceLabel={salePriceLabel ?? ""}
                overrideConfiguredPriceLabel="Price"
              />
            </Grid>
            <Grid item xs={4} sm={2} md={2}>
              <RHStandardSelect
                data-testid={"qty-dropdown-replacement-part-product-card"}
                value={qty}
                onChange={handleQuantityChange}
              >
                {Array.from({
                  length:
                    Number(data?.qtyAvailable) > 40
                      ? 40
                      : Number(data?.qtyAvailable)
                }).map((_, index) => (
                  <option key={`qty-${index}`} value={index}>
                    {index}
                  </option>
                ))}
              </RHStandardSelect>
            </Grid>
            <Grid item xs={4} sm={4} md={4}>
              <ReplacementPartProductCardActions
                replacementPart={data}
                productId={productId}
                productDisplayName={productDisplayName}
                salePriceLabel={salePriceLabel}
                handleAddToCartClick={handleAddToCartClick}
                isDisabled={!qty}
                qty={qty}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} style={{ marginTop: theme.spacing(2) }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                className={typographyStyles.rhBaseCaption}
                display="block"
                style={{
                  fontSize: "13px",
                  textTransform: "uppercase"
                }}
              >
                AVAILABILITY &amp; DELIVERY
              </Typography>

              {data?.inventoryMessage && (
                <Typography
                  className={typographyStyles.rhBaseCaption}
                  component="p"
                  style={{
                    marginTop: theme.spacing(1),
                    textTransform: "initial"
                  }}
                >
                  {data?.inventoryMessage}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {!!cartDialog && (
        <AddToCartDialog
          open={!!cartDialog}
          onClose={handleAddToCartDialogClose}
          monogramOrder={monogramOrder}
          productId={productId}
          fullSkuId={data?.fullSkuId}
          atgSkuId={data?.atgSkuId}
          productDisplayName={productDisplayName}
          options={data?.optionList}
          qty={qty}
          salePriceLabel={salePriceLabel}
          pricing={data?.skuPriceInfo}
          onCompleted={cartDetails => {
            const pricing = data?.skuPriceInfo;
            analyticsLoader(a =>
              a.emitAnalyticsEvent(
                document.querySelector<HTMLInputElement>("#spa-root > *")!,
                a.EVENTS.GA4_ADD_TO_CART.INT_TYPE,
                {
                  cartdetails: {
                    cartId: cartDetails?.id,
                    cartCreationDate: cartDetails?.createdAt,
                    cartUpdationDate: cartDetails?.lastModifiedAt
                  },
                  item: {
                    id: productId,
                    name: productDisplayName,
                    quantity: qty,
                    sku: data?.fullSkuId,
                    pricing: (pricing?.listPrice || 0) * qty,
                    currencyCode: cartDetails?.cartPrice?.currencyCode,
                    color: data?.optionList?.find(
                      opt => opt?.optionType === "Color"
                    )?.label
                  },
                  store_number: "Website",
                  item_list_name: "ReplacememtPartProductCard"
                }
              )
            );
          }}
        />
      )}
    </div>
  );
};
ReplacementPartProductCard.defaultProps = {};

export default memoize(ReplacementPartProductCard);
