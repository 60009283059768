import React, { FC } from "react";
import InStockOnSaleLink from "component-in-stock-on-sale-link";
import { usePageContent } from "customProviders/LocationProvider";
import { useRelatedProductCardData } from "./useRelatedProductCardData";
import { OnSaleDialogContent } from "component-in-stock-dialog-content";
import { RelatedProductCardProps } from "./types";
import { ON_SALE } from "resources/page-level-resources-schemas/product.json";
import memoize from "utils/memoize";

export const RelatedProductOnSaleButton: FC<RelatedProductCardProps> = memoize(
  (props: RelatedProductCardProps) => {
    const { pageContent } = usePageContent();

    const {
      monogramOrder,
      data,
      isMetaProduct,
      pli,
      authenticated,
      customProductConfig,
      userType,
      postalCode
    } = useRelatedProductCardData(props);

    return (
      <>
        <InStockOnSaleLink
          id="component-relatedProductCartd_view-sale-btn"
          text={pageContent?.SALE}
          drawerTitle={`<span class="text-red-600">${
            pageContent?.ON_SALE ?? ON_SALE
          }</span>`}
          postalCode={pli?.sku?.delivery?.postalCode ?? postalCode}
        >
          <OnSaleDialogContent
            monogramOrder={monogramOrder}
            productName={data?.displayName}
            productId={data?.id}
            metaProduct={isMetaProduct}
            postalCode={pli.sku?.delivery?.postalCode ?? postalCode}
            salePriceLabel={data?.priceRangeDisplay?.salePriceLabel ?? ""}
            isAuthenticated={authenticated}
            customInfo={customProductConfig}
            userType={userType || ""}
            multiSkuAdditionalMessage={data?.multiSkuAdditionalMessage ?? ""}
          />
        </InStockOnSaleLink>
      </>
    );
  }
);
