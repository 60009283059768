export const setFeetInchesAsDecimal = ({ feet = 0, inches = 0 }) => {
  return Number(feet) * 12 + Number(inches);
};

export const setFeetAsDecimal = ({ feet = 0, inches = 0 }) => {
  return Number(feet) + Number(inches) / 12;
};

export const getSwatchOptionData = (selectedSwatches: { [key: string]: any }) =>
  selectedSwatches &&
  Object?.values(selectedSwatches)?.reduce((prev, next) => {
    let obj: any = { ...prev };
    next?.options?.map((opt: any) => {
      if (next?.groupMaterial) {
        obj[next?.groupMaterial] = opt?.id;
      }
    });
    next?.options?.map((opt: any) => {
      if (opt?.optionType) {
        obj[opt?.optionType] = opt?.id;
      }
    });
    return obj;
  }, {});

export const getCustomProductOptions = ({ lineItem, customOptions }) => {
  let customProductOptions = {};
  const { shape } =
    lineItem?.customProductOptions?.customProductInformation || {};

  if (shape === "rectangle") {
    customProductOptions = {
      length: setFeetInchesAsDecimal(customOptions?.length),
      width: setFeetInchesAsDecimal(customOptions?.width),
      isCustom: true
    };
  } else if (shape === "round") {
    customProductOptions = {
      diameter: setFeetInchesAsDecimal(customOptions?.diameter),
      isCustom: true
    };
  }
  return customProductOptions;
};

export const populateSwatchesToOptions = (
  selectedSwatches?: ProductSwatch[],
  availableOptions?: ProductAvailableOptionSet[]
): string[] => {
  return (
    selectedSwatches?.reduce(
      (acc, swatch) =>
        swatch.options.reduce((options, option) => {
          const selectedOption = availableOptions
            ?.find(
              availableOption => availableOption.type === option.optionType
            )
            ?.options?.find(opt => opt.id === option.id);
          return [...options, ...(selectedOption ? [selectedOption.id] : [])];
        }, acc),
      [] as string[]
    ) ?? []
  );
};
