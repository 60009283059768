import React, { FC } from "react";
import { Grid, Button, FormControl } from "utils/material-ui-core";
import { useEnv } from "hooks/useEnv";
import yn from "yn";
import { useIsTradeOrContract } from "hooks/useIsTradeOrContract";
import ProductAddToButton from "component-related-product-card/ProductAddToButton";
import ProductAddToButtonV2 from "component-related-product-card/ProductAddToButtonV2";

import memoize from "utils/memoize";
import { usePageContent } from "customProviders/LocationProvider";
import { useCountry } from "hooks/useCountry";

import { useParams2 } from "hooks/useParams";
import { checkCountryIsEu } from "utils/checkCountryIsEu";
import GiftRegistryButton from "../GiftRegistryButton";
import { useRhUserAtomValue } from "hooks/atoms";
import { useKeycloak } from "utils/Keycloak/KeyCloak";
import { useStyles } from "./RelatedProductActionsV2.useStyles";
import { ERelatedProductActionsV2Props } from "./types";
import cn from "classnames";

export const ERelatedProductActionsV2: FC<ERelatedProductActionsV2Props> = ({
  isDisabled,
  isAuthenticated,
  hasInventory,
  isPreOrder,
  handleAddToCartClick,
  handleAddToWishlistClick,
  handleAddToGiftRegistryClick,
  extraFormFragments,
  priceComponent,
  isNewPDPV2,
  ...rest
}) => {
  const env = useEnv();
  const { params: queryParams } = useParams2<{ [key: string]: string }>(
    { version: "" },
    { toLowerCase: true }
  );
  const isNewPDPLayout = queryParams?.version === "v2" || isNewPDPV2;
  const { userType } = useRhUserAtomValue();
  const { keycloak } = useKeycloak();
  const isContractTrade = ["contract", "trade"].includes(
    userType?.toLowerCase() || ""
  );
  const isNotTradeOrContract = !useIsTradeOrContract();
  const classes = useStyles({ isNewPDPLayout })();
  const country = useCountry();
  const { pageContent } = usePageContent();
  const isSignedIn =
    yn(env.FEATURE_ADD_TO_WISHLIST) &&
    !isContractTrade &&
    keycloak?.authenticated &&
    country !== "GB";

  const countryIsEu = checkCountryIsEu({ country });

  return (
    <Grid container justify="space-between" alignItems="center" spacing={2}>
      {!!extraFormFragments &&
        extraFormFragments.map((form, index) => (
          <React.Fragment key={index}>{form}</React.Fragment>
        ))}
      <Grid
        item
        xs={12}
        sm={rest?.isCustomProduct || isNewPDPLayout ? 12 : 6}
        md={12}
      >
        <FormControl
          className={`${classes.addToCartForm} ${
            isSignedIn ? classes.signedIn : ""
          }`}
        >
          <Button
            id="component-related-product-card_add-to-cart-btn"
            variant="contained"
            color="primary"
            onClick={handleAddToCartClick}
            disabled={isDisabled}
            data-testid="add-to-cart-dialog-opener"
            className={cn(
              classes.containedPrimary,
              `!text-white !bg-black !p-4 !hover:bg-gray-1 !hover:border-gray-1 ${
                isNewPDPLayout
                  ? "!xl:w-full !md:w-full !sm:w-full xs:h-auto"
                  : "xl:w-[370px] md:w-[317px] sm:w-[582px] xs:h-14"
              } h-auto lg:w-full`
            )}
          >
            {isNewPDPLayout ? (
              <Grid
                container
                className="!flex !items-center !justify-center !gap-y-1 !gap-x-2 sm:!gap-y-1 sm:!gap-x-2 md:!gap-y-1 md:!gap-x-2 lg:!gap-y-1 lg:!gap-x-2 xl:!gap-y-1 xl:!gap-x-2"
              >
                <Grid item className="!self-center">
                  {isPreOrder
                    ? ` ${pageContent?.PRE_ORDER}`
                    : `${pageContent?.ADD_TO_CART?.toUpperCase()}`}
                </Grid>
                <Grid item className="!self-center">
                  {priceComponent}
                </Grid>
              </Grid>
            ) : (
              <>
                {isPreOrder
                  ? `${pageContent?.PRE_ORDER}`
                  : `${pageContent?.ADD_TO_CART?.toUpperCase()}`}
              </>
            )}
          </Button>
        </FormControl>
      </Grid>
      {isNewPDPLayout && (
        <Grid item>
          <div className="flex flex-row justify-center gap-5 w-[343px] cursor-pointer sm:w-[704px] md:w-[317px] lg:w-[347px]">
            {yn(env.FEATURE_ADD_TO_GIFT_REGISTRY) &&
              isNotTradeOrContract &&
              !countryIsEu &&
              isAuthenticated && (
                <FormControl className="!items-end">
                  <GiftRegistryButton
                    handleOnClick={handleAddToGiftRegistryClick}
                    disabled={isDisabled}
                    isCustomProduct={rest?.isCustomProduct}
                    isNewPDPLayout={isNewPDPLayout}
                  />
                </FormControl>
              )}
            {yn(env.FEATURE_ADD_TO_WISHLIST) &&
              !isContractTrade &&
              keycloak?.authenticated &&
              !countryIsEu && (
                <FormControl
                  margin="none"
                  data-testid={"add-to-wish-list"}
                  className="!items-start"
                >
                  <ProductAddToButtonV2
                    variant="outlined"
                    color="primary"
                    onClick={handleAddToWishlistClick}
                    disabled={!(hasInventory && !isDisabled)}
                    text={pageContent?.Add_to_Wishlist}
                    id="component-relatedProductActions_addToWishlist-btn"
                    isNewPDPLayout={isNewPDPLayout}
                  />
                </FormControl>
              )}
          </div>
        </Grid>
      )}
      {!isNewPDPLayout &&
        yn(env.FEATURE_ADD_TO_WISHLIST) &&
        !isContractTrade &&
        keycloak?.authenticated &&
        country !== "GB" && (
          <Grid item xs={12} sm={rest?.isCustomProduct ? 12 : 6} md={12}>
            <FormControl
              margin="none"
              data-testid={"add-to-wish-list"}
              className="!flex !justify-center !items-center !mb-9 !w-[343px] xs:!w-[343px] sm:!w-[582px] md:!w-[317px] lg:!w-[347px] xl:!w-[370px]"
            >
              <ProductAddToButton
                variant="outlined"
                color="primary"
                onClick={handleAddToWishlistClick}
                disabled={!(hasInventory && !isDisabled)}
                text={pageContent?.ADD_TO_WISHLIST}
                id="component-relatedProductActions_addToWishlist-btn"
                isCustomProduct={rest?.isCustomProduct}
              />
            </FormControl>
          </Grid>
        )}
    </Grid>
  );
};

ERelatedProductActionsV2.defaultProps = {};
export default memoize(ERelatedProductActionsV2);
