import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import useParams, { useGetAllParams } from "hooks/useParams";
import { filterCustomRugOptionTypes } from "utils/customProductHelper";
import { processEnvServer } from "hooks/useSsrHooks";
import { isSSRToggledWithClientRender } from "utils/ssrHelpers";
import { ApolloQueryResult, useApolloClient } from "@apollo/client";
import { useRhUserAtomValue } from "hooks/atoms";
import { useEnv } from "hooks/useEnv";
import yn from "yn";
import useMeasurementType from "hooks/useMeasurementType";
import { useCookies } from "hooks/useCookies";
import useSite from "hooks/useSite";
import useLocale from "hooks/useLocale/useLocale";
import { AddOnConfigModes, AddOnDataType } from "components/AddOnCheckBox";
import {
  countryCurrencyMapper,
  currencySymbolToCurrencyCodeMapper
} from "resources/countries-config.json";
import {
  querGetSku,
  queryLineItemLite,
  queryLineItemSku,
  queryMultiSkuComponentsV2
} from "graphql-client/product";
import { UseRequestProvider } from "customProviders/Provider";
import { getCountryCodeFromPostalCodeAndSiteId } from "utils/postalcode-validation";
import {
  getCustomProductOptions,
  setFeetAsDecimal,
  setFeetInchesAsDecimal
} from "./helper";
import useDidMountEffect from "hooks/useDidMountEffect";
import { useProductImage } from "hooks/useProductImage";
import { useQuantity } from "@RHCommerceDev/hooks/useQuantity";
import { useAppId } from "hooks/useAppId";
import { useCountry } from "hooks/useCountry";
import { MonogramOrderProps } from "pages/MonogramDialog";
import { MONOGRAM_ORDER_DEFAULT } from "utils/constants";
import { usePageContent } from "customProviders/LocationProvider";
import { getSwatchOptionData } from "./helper";
import { find, flatMap, intersection, isEmpty } from "lodash";
import template from "lodash/template";
import analyticsLoader from "analytics/loader";
import { defaultRideAlongData } from "page-product";
import { multiSkuPriceMapper } from "component-price-range-display/utils/convertFusionPriceToRange";
import { cleanMonogramOrder } from "utils/monogramUtils";
import { getReqContext } from "utils/reqContext";
import { addOnDataType } from "component-product-addon/types";

const DEFAULT_MAX_QUANTITY = 40;

export const useRelatedProductCardData = props => {
  const {
    setSelectedHeroImageSwatchUrl,
    fullSkuId,
    selectedSwatches,
    selectedQuantity,
    postalCode,
    saleContextFilter,
    changeSwatchImageFromLineItem,
    handleAddAllItemToCart,
    handleRemoveItemFromAddAll,
    lineItemIndex,
    onOptionsSelected,
    isShellProduct,
    rideAlongData,
    setRideAlongData,
    authenticated,
    inStockReady,
    selectedRugTrimSwatchId,
    selectedRugTrimSwatchIds,
    changeSelectedOptions,
    setMultiSkuComponentsData,
    isNewPDPLayout,
    spoOverrideMessage,
    setProductAddonItems,
    setPrintFullSkuId,
    handleOnSwatchClick,
    allSwatchGroups,
    parentChosenLineItemOptions,
    parentChosenLineItemOptionIds,
    heroImageLevel,
    swatchGroups,
    setPreConfiguredSku
  } = props;

  const req: { path?: string } = processEnvServer
    ? useContext(UseRequestProvider)
    : {};
  const pathname = processEnvServer ? req?.path : location.pathname;

  const selectedSize = useRef<string | null>(null);
  const selectedIds = useRef<ProductAvailableOption[]>([]);

  const env = useEnv();
  const siteId = useSite();
  const locale = useLocale();
  const brand = useSite();
  const country = useCountry();
  const client = useApolloClient();
  const { userType } = useRhUserAtomValue();
  const { isConcierge } = useAppId();
  const measureSystem = useMeasurementType();
  const { pageContent } = usePageContent();
  // const {
  //   previousState: { country }
  // } = useUserPreferences();

  const PDP_DEFAULT_QTY = Number(env.FEATURE_PDP_DEFAULT_QUANTITY);
  const addAllToCartFlag = yn(env.FEATURE_PDP_ADD_ALL);
  const internationalFlag = yn(env.FEATURE_INTERNATIONAL);
  const enableHeroColorizationWithLineItem = yn(
    env.FEATURE_PDP_HERO_COLORIZATION_WITH_LINE_ITEM
  );

  const [cookies] = useCookies(["pc"]);

  const data = useMemo(() => {
    return {
      ...props?.data,
      productLineItem: filterCustomRugOptionTypes(props?.data?.productLineItem)
    };
  }, [props?.data]);

  const params = useParams({
    productId: "",
    fullSkuId: "",
    prevFullSkuId: "",
    color: "",
    sale: ""
  });

  const queryParams = useGetAllParams();

  const currencyCode = countryCurrencyMapper[country];

  //   const [
  //     dataFromProductOptionDropdownClick,
  //     setDataFromProductOptionDropdownClick
  //   ] = useState<string | null>(null);

  //   const handleDataFromProductOptionDropdownClick = (data: string) => {
  //     // This function will be called by the child component to update the state in the parent
  //     setDataFromProductOptionDropdownClick(data);
  //   };

  const preconfiguredSku = data?.preconfiguredSku || "";
  const _fullSkuId = data?.productLineItem?.availableOptions?.length
    ? data?.id === params?.productId || pathname?.includes(data?.id)
      ? fullSkuId || preconfiguredSku
      : ""
    : data?.productLineItem?.sku?.inventory?.fullSkuId;

  const isRideAlongProduct = useMemo(
    () => rideAlongData?.casingProduct === data.id,
    [rideAlongData?.casingProduct, data.id]
  );

  // customOptions must default to this?
  const [customOptions, setCustomOptions] = useState({
    diameter: { feet: "", inches: "" },
    width: { feet: "", inches: "" },
    length: { feet: "", inches: "" }
  });

  let [loading, setLoading] = useState(
    isSSRToggledWithClientRender() ? false : true
  );

  let [skuLoading, setSkuLoading] = useState(
    isSSRToggledWithClientRender() ? false : true
  );
  const [refetchOptions, setRefetchOptions] = useState<any>({
    productId: data?.id,
    fullSkuId: _fullSkuId || undefined,
    monogrammable: data?.personalizeInfo.monogrammable,
    postalCode: postalCode,
    qty: 1,
    filter:
      data?.id === params?.productId || data?.onSale ? saleContextFilter : null
  });

  let [lineItem, setLineItem] = useState(
    filterCustomRugOptionTypes(data?.productLineItem)
  );

  //   const [multiSkuComponents, setMultiSkuComponents] = useState<
  //     MultiSkuComponentWithRestrictions[]
  //   >([]);

  //   const [multiSkuInventory, setMultiSkuInventory] =
  //     useState<ProductSkuInventory | null>(null);

  //   const [multiSkuPricing, setMultiSkuPricing] = useState<FusionPrices | null>(
  //     null
  //   );

  //   const [isSwatchImageUpdated, setIsSwatchImageUpdated] = useState(false);
  //   const [inStockIsReady, setInStockIsReady] = useState<any | null>(null);

  const [isMonogrammed, setIsMonogrammed] = useState(false);
  const [lineItemSku, setLineItemSku] = useState<any | null>(null);
  const [skuDetails, setSkuDetails] = useState<any | null>({
    fullSkuId: null,
    components: []
  });
  const [addOnsData, setAddOnsData] = useState<AddOnDataType[]>([]);
  const [addOnProduct, setAddonProduct] = useState<any | null>(null);
  const [panelProdConfigurationData, setPanelProdConfigurationData] =
    useState<Maybe<addOnDataType>>(null);
  const [multiSkuComponentsDataV2, setMultiSkuComponentsDataV2] = useState<any>(
    {}
  );
  const [qty, setQty] = useState(
    env.FEATURE_PDP_DEFAULT_QUANTITY ? PDP_DEFAULT_QTY : 1
  );
  const [panelProdData, setPanelProdData] =
    useState<Maybe<ProductAddonsInfo>>(null);

  const [selectedOptions, setSelectedOptions] = useState<
    ProductAvailableOption[]
  >([]);
  const [giftCardFrom, setGiftCardFrom] = useState<string>("");
  const [giftCardTo, setGiftCardTo] = useState<string>("");
  const [rugTrim, setRugTrim] = useState<string>("");
  const [selectedAddOnProduct, setSelectedAddOnProduct] =
    useState<Maybe<addOnDataType>>(null);
  const [isAllCustomOptionSelected, setIsAllCustomOptionSelect] =
    useState(false);
  const [isCustomOptionSelectionValid, setIsCustomOptionSelectionValid] =
    useState(false);
  const [postalCodeDialog, setPostalCodeDialog] = useState(false);
  const [surchargeInfoDialog, setSurchargeInfoDialog] = useState(false);
  const [cartDialog, setCartDialog] = useState(false);
  const [wishlistDialog, setWishlistDialog] = useState(false);
  const [giftRegistryDialog, setGiftRegistryDialog] = useState(false);
  const [monogramDialog, setMonogramDialog] = useState(false);
  const [monogramOrder, setMonogramOrder] = useState<MonogramOrderProps>(
    MONOGRAM_ORDER_DEFAULT
  );

  //this is responsible for add on products add to cart data
  const [addonDataAddToCart, setAddonDataAddToCart] = useState<any>([]);
  const [isQtyChanged, setIsQtyChanged] = useState(false);
  const [giftCardValueExceed, setGiftCardValueExceed] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [isLineItemImageUrlFailed, setIsLineItemImageUrlFailed] =
    useState<boolean>(false);
  // const [selectedOptionsLineItem, setSelectedOptionsLineItem] = useState("");
  const [addonDataAddToProject, setAddonDataAddToProject] = useState<any>([]);
  const [
    selectedAddOnsNotFullyConfigured,
    setSelectedAddOnsNotFullyConfigured
  ] = useState<boolean>(false);
  const [addToCartItemDetails, setAddToCartItemDetails] =
    useState<addToCartItemDetailsProps>({});

  const { refetchLineItemImage: refetchAddOnLineItemImage } = useProductImage({
    productId: "",
    options: [],
    skip: true
  });

  const { refetchQty: addOnQtyfetch } = useQuantity({
    country,
    sku: "SKU_MISSING_UI",
    skip: true
  });

  //   useEffect(() => {
  //     if (setIsSwatchImageUpdated) setIsSwatchImageUpdated(true);
  //   }, []);

  const updateProductAddonItems = (
    prev: AddOnDataType[],
    addOnProduct: AddOnDataType
  ): AddOnDataType[] => {
    let updatedItems: AddOnDataType[] = [];

    for (let item of prev) {
      if (
        item.productId === addOnProduct?.productId &&
        item.mainItemProductId === addOnProduct?.mainItemProductId
      ) {
        if (
          addOnProduct?.productSelected &&
          addOnProduct?.selectedOptions?.length === addOnProduct?.optionsLength
        ) {
          updatedItems.push({
            ...item,
            qty: addOnProduct?.qty,
            productSelected: addOnProduct?.productSelected,
            pricing: addOnProduct?.pricing,
            preBillMessage: addOnProduct?.preBillMessage,
            fullSkuId: addOnProduct?.fullSkuId,
            spo: addOnProduct?.spo,
            maxQuantity: addOnProduct?.maxQuantity,
            mainItemProductId: addOnProduct?.mainItemProductId,
            selectedOptions: addOnProduct?.selectedOptions
          });
        }
      } else {
        updatedItems.push(item);
      }
    }

    if (
      !prev.some(
        item =>
          item.productId === addOnProduct?.productId &&
          item.mainItemProductId === addOnProduct?.mainItemProductId
      )
    ) {
      if (
        addOnProduct?.productSelected &&
        addOnProduct?.selectedOptions?.length === addOnProduct?.optionsLength
      ) {
        updatedItems.push(addOnProduct);
      }
    }

    return updatedItems;
  };

  useEffect(() => {
    setProductAddonItems?.(prev => {
      if (addOnProduct) {
        return updateProductAddonItems(prev, addOnProduct);
      }
      return prev;
    });
  }, [addOnProduct]);

  //   useEffect(() => {
  //     // const isReady =
  //     //   inStockReady &&
  //     //   typeof inStockReady !== "undefined" &&
  //     //   inStockReady !== "undefined";
  //     // setInStockIsReady?.(isReady ? inStockReady : null);
  //   }, [inStockReady]);

  useEffect(() => {
    const optionIds = getSelectedOptions(lineItem, selectedSwatches);

    setRefetchOptions?.({
      ...refetchOptions,
      fullSkuId: !optionIds?.length ? _fullSkuId : undefined,
      selectedOptionIds: optionIds?.length ? optionIds : undefined,
      shouldFetchSku: lineItem?.availableOptions?.length === optionIds?.length
    });
  }, [selectedSwatches]);

  useEffect(() => {
    if (parentChosenLineItemOptions) {
      const optionIds: any[] = [];
      lineItem?.availableOptions?.forEach(availableOption => {
        if (availableOption?.type in parentChosenLineItemOptions) {
          optionIds?.push(
            parentChosenLineItemOptions[availableOption?.type] ?? ""
          );
        }
      });
    }
  }, [parentChosenLineItemOptions]);

  const handlePreSelectedOptions = (
    queryParamsVal: string,
    availableOptionsObj: ProductAvailableOptionSet,
    selectedOptions: string[]
  ) => {
    let callQuery = false;
    if (availableOptionsObj) {
      let allOptions = availableOptionsObj?.options?.map(option => option?.id);
      const similarKeys = intersection(allOptions, selectedOptions);
      if (similarKeys?.length === 0) {
        const selectedOption = availableOptionsObj?.options?.find(
          option =>
            option?.value?.toLowerCase() === queryParamsVal?.toLowerCase()
        );

        if (selectedOption) {
          selectedOptions = [...selectedOptions, selectedOption.id];
          callQuery = true;
          const swatchGroup =
            allSwatchGroups?.find(
              it => it.groupMaterial === selectedOption?.type
            ) || null;
          const selectedSwatch =
            swatchGroup?.stockedSwatches?.find(
              it => it.options?.[0]?.id === selectedOption?.id
            ) || null;
          if (swatchGroup && selectedSwatch && handleOnSwatchClick) {
            const swatchData = {
              swatchGroup: swatchGroup,
              selectedSwatch: selectedSwatch
            };
            handleOnSwatchClick(swatchData);
          }
        }
      }
    }

    return { selectedOptions, callQuery };
  };

  useEffect(() => {
    if (isRideAlongProduct) {
      return;
    }

    async function fetchQuery() {
      let response: any = await lazyQueryLineItem(
        _fullSkuId
          ? {}
          : {
              fullSkuId: undefined
            }
      );
      if (!response) {
        response = await lazyQueryLineItem({
          fullSkuId: undefined
        });
      }
      let selectedOptions = getSelectedOptions(response, selectedSwatches);
      const customProductOptions = getCustomProductOptions({
        lineItem,
        customOptions
      });

      let isColorPreSelected = false;

      if (queryParams && Object.keys(queryParams).length) {
        Object.keys(queryParams).forEach(key => {
          const matchingOptionSet = response?.availableOptions.find(
            optionSet => optionSet.type.toLowerCase() === key.toLowerCase()
          );
          if (matchingOptionSet) {
            const { selectedOptions: preSelectedOptions, callQuery } =
              handlePreSelectedOptions(
                queryParams?.[key],
                matchingOptionSet,
                selectedOptions
              );
            selectedOptions = preSelectedOptions;
            isColorPreSelected = callQuery;
          }
        });
      }

      const isColorised = isColorPreSelected ? true : false;

      if (
        (selectedOptions?.length > 0 &&
          response?.availableOptions?.length === selectedOptions?.length) ||
        isColorised
      ) {
        const finalVariable = {
          fullSkuId: _fullSkuId || params?.fullSkuId,
          selectedOptionIds: selectedOptions,
          shouldFetchSku:
            response?.availableOptions?.length === selectedOptions?.length ||
            isColorised,
          qty: refetchOptions?.qty,
          ...customProductOptions
        };

        const responseLineItem = await lazyQueryLineItem(finalVariable);
        const responseSku = await lazyQueryLineItemSku(finalVariable);

        isColorPreSelected &&
          refetchLineItemImage({ selectedOptionIds: selectedOptions } as any);
        // setSelectedHeroImageSwatchUrl &&
        //   setSelectedHeroImageSwatchUrl(responseSku?.info?.imageUrl || "");
        if (isColorised) {
          response = { ...responseLineItem };
        } else {
          resolveSku(selectedOptions, customProductOptions, _fullSkuId);
          response = { ...responseLineItem, sku: responseSku };
        }
      }

      setLineItem(response);

      // MULTISKU
      const isProductFullyConfigured =
        response?.availableOptions?.length === selectedOptions?.length;

      const associatedMultiSkuBundleId = response?.sku?.inventory?.fullSkuId;

      const isMultiSkuProduct =
        ["m", "M"]?.includes(associatedMultiSkuBundleId?.[0]) &&
        data?.metaProduct;

      if (isProductFullyConfigured && isMultiSkuProduct) {
        const selectedOptionIds = selectedOptions?.map(optionSet =>
          optionSet?.toString()
        );
        getMultiSkuComponents(
          data?.id,
          selectedOptionIds,
          postalCode,
          associatedMultiSkuBundleId,
          refetchOptions?.qty
        );
      }
      setLoading(false);
    }
    // fetchQuery();
  }, []);

  const lazyQueryGetSku = useCallback(
    async variables => {
      setSkuLoading(true);
      const {
        data: { getSku }
      } = await client.query({
        query: querGetSku,
        variables: {
          productId: data?.id,
          userType: userType,
          monogrammable: data?.personalizeInfo?.monogrammable,
          postalCode,
          currencyCode,
          qty: 1,
          filter:
            data?.id === params?.productId || data?.onSale
              ? saleContextFilter
              : null,
          siteId,
          measureSystem,
          locale: internationalFlag ? locale : undefined,
          isCustom: data?.customProduct,
          ...variables
        },
        fetchPolicy: "network-only",
        errorPolicy: "all"
      });
      setPrintFullSkuId?.(getSku?.fullSkuId || "");

      return getSku as GetSkuResponse;
    },
    [
      client,
      data?.id,
      _fullSkuId,
      data?.personalizeInfo?.monogrammable,
      data?.onSale,
      data?.customProduct,
      userType,
      postalCode,
      currencyCode,
      params?.productId,
      saleContextFilter,
      siteId,
      measureSystem,
      saleContextFilter,
      internationalFlag,
      locale
    ]
  );

  const lazyQueryLineItem = useCallback(
    async variables => {
      setLoading(true);
      const {
        data: { productLineItem }
      } = await client.query({
        query: queryLineItemLite,

        variables: {
          productId: data?.id,
          fullSkuId: _fullSkuId,
          userType: userType,
          monogrammable: data?.personalizeInfo?.monogrammable,
          postalCode,
          currencyCode,
          qty: 1,
          filter:
            data?.id === params?.productId || data?.onSale
              ? saleContextFilter
              : null,
          siteId,
          measureSystem,
          locale: internationalFlag ? locale : undefined,
          shouldFetchSku:
            data?.priceRangeDisplay?.hasOnlyOneSku ||
            !data?.productLineItem?.availableOptions?.length
              ? true
              : false,
          nextGenDriven: data?.customProduct ? false : true,
          shouldFetchCustomProductOptions: data?.customProduct,
          ...variables,
          selectedOptionIds: variables?.selectedOptionIds || []
        },
        fetchPolicy: "network-only",
        errorPolicy: "all",
        context: {
          fetchOptions: {
            method: "POST"
          }
        }
      });

      //SR-72(Filter size options for custom rugs)
      const lineItemResponse = data?.customProduct
        ? filterCustomRugOptionTypes(productLineItem)
        : productLineItem;

      return lineItemResponse as ProductLineItem;
    },
    [
      client,
      data?.id,
      _fullSkuId,
      data?.personalizeInfo?.monogrammable,
      postalCode,
      currencyCode,
      siteId,
      measureSystem,
      saleContextFilter,
      internationalFlag,
      locale,
      userType,
      pageContent
    ]
  );

  const lazyQueryLineItemSku = useCallback(
    async variables => {
      // setSkuLoading(true);
      setLoading(true);
      const optionIds = variables?.selectedOptionIds;

      const {
        data: { lineItemSku }
      } = await client.query({
        query: queryLineItemSku,
        variables: {
          productId: data?.id,
          fullSkuId: _fullSkuId,
          userType: userType,
          monogrammable: data?.personalizeInfo?.monogrammable,
          postalCode,
          currencyCode,
          qty: variables?.qty ?? 1,
          filter:
            data?.id === params?.productId || data?.onSale
              ? saleContextFilter
              : null,
          siteId,
          measureSystem,
          locale: internationalFlag ? locale : undefined,
          shouldFetchSku: true,
          nextGenDriven: data?.customProduct ? false : true,
          shouldFetchCustomProductOptions: data?.customProduct,
          ...variables,
          selectedOptionIds: optionIds
        },
        fetchPolicy: "network-only",
        errorPolicy: "all"
      });
      // setSkuLoading(false);
      setLoading(false);
      return lineItemSku as ProductSku;
    },
    [
      client,
      data?.id,
      data?.personalizeInfo?.monogrammable,
      data?.onSale,
      data?.customProduct,
      _fullSkuId,
      userType,
      postalCode,
      currencyCode,
      params?.productId,
      saleContextFilter,
      siteId,
      measureSystem,
      internationalFlag,
      locale
    ]
  );

  const resolveSku = (
    selectedOptionIds,
    customProductOptions,
    fullSkuId = undefined
  ) => {
    async function fetchQuery() {
      const optionIds = selectedOptionIds;

      const lineItemSkuResponse = await lazyQueryGetSku({
        fullSkuId,
        selectedOptionIds: optionIds,
        ...customProductOptions
      });
      setSkuDetails(lineItemSkuResponse);
      if (params?.productId === data?.id) {
        setPreConfiguredSku?.(lineItemSkuResponse?.fullSkuId);
      }
      setSkuLoading(false);
    }
    fetchQuery();
  };

  const resolveSkuDetails = (
    selectedOptionIds,
    lineItemResponse,
    data,
    lineItem
  ) => {
    async function fetchQuery() {
      setLoading(true);
      // setSkuLoading(true);
      const optionIds = selectedOptionIds;
      const customProductOptions = getCustomProductOptions({
        lineItem,
        customOptions
      });

      const lineItemSkuResponse = await lazyQueryLineItemSku({
        fullSkuId: undefined,
        selectedOptionIds: optionIds,
        shouldFetchSku: true,
        qty: refetchOptions?.qty,
        ...customProductOptions
      });
      const combinedResponse = {
        ...lineItemResponse,
        sku: lineItemSkuResponse
      };
      setLineItemSku({ sku: lineItemSkuResponse });
      setIsCustomRug(false);
      setLineItem(combinedResponse);
      const associatedMultiSkuBundleId =
        lineItemSkuResponse?.inventory?.fullSkuId;

      const isMultiSkuProduct =
        associatedMultiSkuBundleId?.[0]?.toLowerCase() === "m" &&
        data?.metaProduct;

      checkRideAlongData(combinedResponse);

      if (isMultiSkuProduct) {
        getMultiSkuComponents(
          data?.id,
          selectedOptionIds,
          postalCode,
          associatedMultiSkuBundleId
        );
      }
    }
    fetchQuery();
  };

  const getSelectedOptions = (
    response: any,
    swatches: any,
    getDiffOptions: boolean = false,
    forcedOption: { [key: string]: string } = {},
    isMultiSku: boolean = false
  ) => {
    let lineItemPreselected: { [key: string]: any } = {};
    if (response?.availableOptions) {
      response?.availableOptions?.map((option: ProductAvailableOptionSet) => {
        const options = option?.options?.filter(
          o => o?.status !== "unavailable"
        );
        if (options?.length === 1) {
          const childOption = options?.[0];
          lineItemPreselected[childOption?.type] = childOption?.id;
        }
        options?.map((childOption: ProductAvailableOption) => {
          if (childOption?.status === "selected") {
            lineItemPreselected[childOption?.type] = childOption?.id;
          }
        });
      });
    }

    const selectedOptions: string[] = Object?.values({
      ...lineItemPreselected,
      ...getSwatchOptionData(swatches),
      ...forcedOption
    });

    // remove id's from selected optionIds which are not part of availableOptions
    const filteredOptions: string[] = [];
    selectedOptions?.forEach(selectedOption => {
      response?.availableOptions?.forEach(option => {
        const matchedOption = option?.options
          ?.filter(o => o?.status !== "unavailable")
          ?.find(o => selectedOption === o?.id);
        if (matchedOption) {
          filteredOptions?.push(selectedOption);
        }
      });
    });

    if (getDiffOptions) {
      return selectedOptions?.filter(x => !filteredOptions?.includes(x));
    }
    if (isMultiSku) {
      return selectedOptions;
    }

    return filteredOptions;
  };

  // decouple line item image code
  const { imageUrl = "", refetchLineItemImage } = useProductImage({
    productId: data?.id,
    options: refetchOptions?.selectedOptionIds?.length
      ? refetchOptions?.selectedOptionIds
      : getSelectedOptions(lineItem, selectedSwatches)?.map(a => ({
          id: a as any as string
        })),
    siteId,
    locale
  });

  const productImageUrl = imageUrl ?? data?.productLineItem?.image?.imageUrl;

  const lazyQueryMultiSkuComponents = useCallback(
    async (
      productId: string,
      selectedOptionIds: string[],
      siteId: string,
      postalCode: string,
      qty: string
    ): Promise<MultiSkuComponentsResponse> => {
      const optionIds = selectedOptionIds;
      setLoading(true);
      // setSkuLoading(true);
      const {
        data: { pullMultiSkuComponents }
      } = await client.query({
        query: queryMultiSkuComponentsV2,
        variables: {
          productId,
          selectedOptionIds: optionIds,
          siteId,
          currencyCode:
            countryCurrencyMapper[
              getCountryCodeFromPostalCodeAndSiteId(postalCode, siteId)
            ],
          countryCode: getCountryCodeFromPostalCodeAndSiteId(
            postalCode,
            siteId
          ),
          postalCode,
          locale,
          qty,
          userType: userType
        }
      });
      setMultiSkuComponentsDataV2(pullMultiSkuComponents);
      setLoading(false);
      // setSkuLoading(false);
      return pullMultiSkuComponents ?? {};
    },
    [client, locale, userType]
  );

  const getMultiSkuComponents = useCallback(
    async (
      productId: string,
      selectedOptionIds: string[],
      postalCode?: string,
      associatedMultiSkuBundleId?: string,
      qty?: string
    ) => {
      const isMultiSkuProduct =
        associatedMultiSkuBundleId?.[0]?.toLowerCase() === "m";
      setLoading(true);
      // setSkuLoading(true);
      if (isMultiSkuProduct) {
        await lazyQueryMultiSkuComponents(
          productId,
          selectedOptionIds,
          siteId,
          postalCode!,
          qty! || refetchOptions?.qty
        );
      }
      // setSkuLoading(false);
      setLoading(false);
    },
    [lazyQueryMultiSkuComponents, siteId, refetchOptions?.qty]
  );

  const checkRideAlongData = useCallback(
    skuInfoResponse => {
      if (skuInfoResponse?.sku?.info?.hasCasingSkus) {
        setRideAlongData?.({
          triggerProductId: data?.id,
          casingProduct: skuInfoResponse?.sku?.info?.casingProduct,
          replacementCushionProduct:
            skuInfoResponse?.sku?.info?.replacementCushionProduct,
          selectedSizeId: selectedSize?.current
        });
      }

      if (
        data?.id === rideAlongData?.triggerProductId &&
        !skuInfoResponse.sku.info.hasCasingSkus
      ) {
        setRideAlongData?.(defaultRideAlongData);
      }
    },
    [data?.id, rideAlongData?.triggerProductId, setRideAlongData]
  );

  const refetchLineItemDetails = async () => {
    setSkuDetails({ fullSkuId: null, components: [] });

    const defaultOptions =
      !refetchOptions || !refetchOptions?.selectedOptionIds
        ? {
            fullSkuId: _fullSkuId || undefined,
            selectedOptionIds: undefined,
            postalCode,
            currencyCode,
            measureSystem,
            qty: 1
          }
        : { ...refetchOptions, postalCode, currencyCode, measureSystem };

    let finalOptions = { ...defaultOptions, isMonogrammed };

    // customOptions only gets set if customProduct
    // map defaultOptions with customOptions
    const finalSelectedOptions = refetchOptions?.selectedOptionIds?.length
      ? refetchOptions?.selectedOptionIds
      : [];

    // TO REFETCH LINEITME IMAGES START
    const options = {
      selectedOptionIds: finalSelectedOptions
    };

    refetchLineItemImage(options);
    // TO REFETCH LINEITME IMAGES END

    const customProductOptions = getCustomProductOptions({
      lineItem,
      customOptions
    });

    finalOptions = {
      ...defaultOptions,
      selectedTrimOptionIds: selectedRugTrimSwatchIds,
      shouldFetchSku:
        lineItem?.availableOptions?.length === finalSelectedOptions?.length,
      selectedOptionIds: finalSelectedOptions,
      productId: data?.id
    };
    if (data?.customProduct) {
      finalOptions = { ...finalOptions, ...customProductOptions };
    }

    let response = await lazyQueryLineItem(finalOptions);

    if (response) {
      setLineItem(prev => ({ ...prev, ...response }));
      //   setMultiSkuComponents([]);
      //   setMultiSkuPricing(null);
      //   setMultiSkuInventory(null);
      setLoading(false);
      // if (dataFromProductOptionDropdownClick !== null) {
      //   setDataFromProductOptionDropdownClick(null);
      // }

      const selectedOptions = getSelectedOptions(response, selectedSwatches);
      finalOptions.selectedOptionIds = selectedOptions;
    }

    let isProductFullyConfigured = false;
    const isOtherOptionsSelected =
      lineItem?.availableOptions?.length ===
      finalOptions?.selectedOptionIds?.length;

    if (data?.customProduct) {
      const lengthFeet = customOptions?.length?.feet;
      const widthFeet = customOptions?.width?.feet;
      const diameterFeet = customOptions?.diameter?.feet;
      const isLengthAndWidthConfigured = lengthFeet !== "" && widthFeet !== "";
      const isDiameterConfigured =
        diameterFeet !== "" || diameterFeet === undefined;
      if (
        (isLengthAndWidthConfigured || isDiameterConfigured) &&
        isOtherOptionsSelected
      ) {
        isProductFullyConfigured = true;
      }
    } else {
      isProductFullyConfigured = response && isOtherOptionsSelected;
    }

    if (isProductFullyConfigured) {
      resolveSkuDetails(finalSelectedOptions, response, data, lineItem);
      resolveSku(finalSelectedOptions, customProductOptions);
    }
  };

  useDidMountEffect(() => {
    refetchLineItemDetails();
  }, [
    refetchOptions,
    customOptions,
    isMonogrammed,
    currencyCode,
    postalCode,
    measureSystem,
    locale
  ]);

  //   const onOptionsChangeCb = useCallback((options: ProductAvailableOption[]) => {
  //     changeSelectedOptions && changeSelectedOptions(options);
  //   }, []);

  const onChangeCb = useCallback(
    (selectedOptions: ProductAvailableOption[], qty: number): void => {
      if (
        enableHeroColorizationWithLineItem &&
        data?.emptyProduct === false &&
        handleOnSwatchClick
      ) {
        const selectedOption = selectedOptions.find(it =>
          ["Finish", "Color"].includes(it.type)
        );
        const swatchGroup =
          allSwatchGroups?.find(
            it => it.groupMaterial === selectedOption?.type
          ) || null;
        const selectedSwatch =
          swatchGroup?.stockedSwatches?.find(
            it => it.options?.[0]?.id === selectedOption?.id
          ) || null;
        if (
          swatchGroup &&
          selectedSwatch &&
          ["Finish", "Color"].includes(swatchGroup?.groupMaterial || "")
        ) {
          const swatchData = {
            swatchGroup: swatchGroup,
            selectedSwatch: selectedSwatch
          };
          handleOnSwatchClick(swatchData);
          return;
        } else if (selectedOption?.type === "Color") {
          let allSwatches: ProductSwatch[] = [];
          allSwatchGroups?.forEach((obj: ProductSwatchGroup) => {
            const stockedSwatches = obj?.stockedSwatches || [];
            const customSwatches = obj?.customSwatches || [];
            allSwatches = allSwatches.concat(stockedSwatches, customSwatches);
          });
          const swatchGroup = find(allSwatchGroups, parent =>
            flatMap(
              [
                ...(parent.stockedSwatches || []),
                ...(parent.customSwatches || [])
              ],
              "options"
            ).some(option => option.id === selectedOption?.id)
          );
          const selectedSwatch =
            allSwatches?.find((it: any) => {
              const optionObj = it?.options?.find(
                opItem => opItem?.optionType === "Color"
              );
              return optionObj?.id === selectedOption?.id;
            }) || null;
          if (swatchGroup && selectedSwatch) {
            const swatchData = {
              swatchGroup: swatchGroup,
              selectedSwatch: {
                ...selectedSwatch,
                options: selectedOptions.find(it => it.type === "Fabric")
                  ? selectedSwatch.options
                  : selectedSwatch.options.filter(
                      option => option.optionType === "Color"
                    )
              }
            };
            handleOnSwatchClick(swatchData);
            return;
          }
        }
      }
      setLoading(true);
      // setSkuLoading(true);

      selectedOptions?.forEach(option => {
        if (option?.type === "Size") {
          selectedSize.current = option?.id;
        }
      });
      const optionIds = Array.from(
        new Set(selectedOptions?.map(selectedOption => selectedOption.id))
      );

      /**
       * set newly selected refetch options here
       * shouldFetchSku should only true when product is full configured i.e all configure options are selected
       */
      setRefetchOptions?.({
        ...refetchOptions,
        fullSkuId: !optionIds?.length ? _fullSkuId : undefined,
        selectedOptionIds: optionIds?.length ? optionIds : undefined,
        qty,
        shouldFetchSku: lineItem?.availableOptions?.length === optionIds?.length
      });
      return onOptionsSelected && onOptionsSelected(!!selectedOptions);
    },
    [_fullSkuId, lineItem, setRefetchOptions]
  );

  const lazyQueryAddOnGetSku = useCallback(
    async variables => {
      const {
        data: { getSku }
      } = await client.query({
        query: querGetSku,
        variables: {
          productId: variables?.productId,
          userType: userType,
          postalCode,
          currencyCode: currencyCode,
          qty: 1,
          filter: null,
          siteId,
          measureSystem,
          locale: internationalFlag ? locale : undefined,
          fullSkuId: undefined,
          selectedOptionIds: variables?.selectedOptionIds
        },
        fetchPolicy: "network-only",
        errorPolicy: "all"
      });
      return getSku as GetSkuResponse;
    },
    [
      client,
      postalCode,
      currencyCode,
      siteId,
      measureSystem,
      internationalFlag,
      locale,
      userType,
      pageContent
    ]
  );

  const onAddonConfigChangeCb = useCallback(
    (addOnProduct: AddOnDataType, addOnConfigModes: AddOnConfigModes): void => {
      async function fetchAddOnLineItemQuery() {
        setLoading(true);
        const { panelProdComputation, mainItemOptionsChanged } =
          addOnConfigModes;
        const optionIds = addOnProduct?.selectedOptions?.map(
          selectedOption => selectedOption.id
        );

        const addOnData = data?.productAddons?.productAddonsInfo.find(
          addOn => addOn?.id === addOnProduct?.productId
        );

        const [
          {
            data: {
              productImage: { imageUrl }
            }
          },
          response
        ] = await Promise.all([
          refetchAddOnLineItemImage({
            productId: addOnProduct?.productId ?? "",
            selectedOptionIds: optionIds
          }),
          lazyQueryLineItem({
            productId: addOnProduct?.productId,
            fullSkuId: undefined,
            selectedOptionIds: optionIds?.length ? optionIds : [],
            postalCode: postalCode,
            monogrammable: false,
            qty: addOnProduct?.qty,
            shouldFetchSku: addOnProduct?.optionsLength === optionIds?.length
          })
        ]);

        if (response) {
          let lineItemSkuResponse: Maybe<ProductSku> = null;
          let getSkuResponse: Maybe<GetSkuResponse> = null;
          let skuAvailabilityStatusResponse: Maybe<
            ApolloQueryResult<Pick<Query, "skuAvailabilityStatus">>
          > = null;

          if (
            addOnData?.productLineItem?.availableOptions?.length ===
            optionIds?.length
          ) {
            const getSkuPromise = lazyQueryAddOnGetSku({
              productId: addOnProduct?.productId,
              selectedOptionIds: optionIds
            });

            [lineItemSkuResponse, getSkuResponse] = await Promise.all([
              lazyQueryLineItemSku({
                productId: addOnProduct?.productId,
                postalCode: postalCode,
                selectedOptionIds: optionIds?.length ? optionIds : undefined,
                qty: addOnProduct?.qty,
                shouldFetchSku:
                  addOnProduct?.optionsLength ===
                  addOnProduct?.selectedOptions?.length
              }),
              getSkuPromise
            ]);

            skuAvailabilityStatusResponse = await addOnQtyfetch?.({
              country,
              sku: getSkuResponse?.fullSkuId ?? "SKU_MISSING_UI"
            });
          }
          setLoading(true);
          setSkuLoading(true);
          setAddonProduct({
            ...addOnProduct,
            fullSkuId: getSkuResponse?.fullSkuId,
            colorizedImgURL: imageUrl,
            spo: lineItemSkuResponse?.restrictions?.spo,
            preBillMessage: lineItemSkuResponse?.restrictions?.preBillMessage,
            pricing: lineItemSkuResponse?.info?.skuPriceInfo,
            itemOptions: response?.availableOptions,
            maxQuantity:
              skuAvailabilityStatusResponse?.data?.skuAvailabilityStatus
                ?.maxQuantity,
            mainItemProductId: data?.id
          });
          if (!panelProdComputation) {
            setAddOnsData(existingAddOns => {
              const otherAddOns = existingAddOns?.filter(
                existingAddOn =>
                  existingAddOn?.productId !== addOnProduct?.productId
              );

              const currAddOn = existingAddOns?.find(
                existingAddOn =>
                  existingAddOn?.productId === addOnProduct?.productId
              );

              const newAddOnSet = (
                currAddOn
                  ? addOnProduct?.productSelected || mainItemOptionsChanged
                    ? [
                        ...otherAddOns,
                        {
                          ...currAddOn,
                          productSelected: addOnProduct?.productSelected,
                          fullSkuId: getSkuResponse?.fullSkuId,
                          colorizedImgURL: imageUrl,
                          spo: lineItemSkuResponse?.restrictions?.spo,
                          preBillMessage:
                            lineItemSkuResponse?.restrictions?.preBillMessage,
                          pricing: lineItemSkuResponse?.info?.skuPriceInfo,
                          qty: addOnProduct?.qty,
                          itemOptions: response?.availableOptions,
                          selectedOptions: addOnProduct?.selectedOptions,
                          maxQuantity:
                            skuAvailabilityStatusResponse?.data
                              ?.skuAvailabilityStatus?.maxQuantity
                        }
                      ]
                    : [...otherAddOns]
                  : [
                      ...otherAddOns,
                      {
                        ...addOnProduct,
                        fullSkuId: getSkuResponse?.fullSkuId,
                        colorizedImgURL: imageUrl,
                        spo: lineItemSkuResponse?.restrictions?.spo,
                        preBillMessage:
                          lineItemSkuResponse?.restrictions?.preBillMessage,
                        pricing: lineItemSkuResponse?.info?.skuPriceInfo,
                        qty: addOnProduct?.qty,
                        itemOptions: response?.availableOptions,
                        selectedOptions: addOnProduct?.selectedOptions,
                        maxQuantity:
                          skuAvailabilityStatusResponse?.data
                            ?.skuAvailabilityStatus?.maxQuantity
                      }
                    ]
              ) as AddOnDataType[];

              return newAddOnSet;
            });
          } else {
            setPanelProdConfigurationData({
              ...addOnProduct,
              fullSkuId: getSkuResponse?.fullSkuId ?? "",
              colorizedImgURL: imageUrl,
              spo: lineItemSkuResponse?.restrictions?.spo,
              preBillMessage: lineItemSkuResponse?.restrictions?.preBillMessage,
              pricing: lineItemSkuResponse?.info?.skuPriceInfo,
              qty: addOnProduct?.qty,
              itemOptions: response?.availableOptions ?? [],
              selectedOptions: addOnProduct?.selectedOptions,
              maxQuantity:
                skuAvailabilityStatusResponse?.data?.skuAvailabilityStatus
                  ?.maxQuantity
            });
          }
        }
        setLoading(false);
        setSkuLoading(false);
      }
      fetchAddOnLineItemQuery();
    },
    [_fullSkuId, lineItem, postalCode, addOnsData]
  );

  useEffect(() => {
    if (changeSwatchImageFromLineItem) {
      if (productImageUrl) {
        changeSwatchImageFromLineItem(productImageUrl);
      }
    }
  }, [productImageUrl]);

  useEffect(() => {
    if (isRideAlongProduct) {
      const selectedOptionIds = getSelectedOptions(
        lineItem,
        selectedSwatches,
        false,
        rideAlongData?.selectedSizeId
          ? { Size: rideAlongData?.selectedSizeId }
          : {}
      );

      /**
       * set newly selected refetch options here
       * shouldFetchSku should only true when product is full configured i.e all configure options are selected
       */
      setRefetchOptions?.(refetchOptions => ({
        ...refetchOptions,
        fullSkuId: undefined,
        selectedOptionIds:
          selectedOptionIds?.length > 0 ? selectedOptionIds : undefined,
        shouldFetchSku:
          lineItem?.availableOptions?.length === selectedOptionIds?.length
      }));
      onOptionsSelected?.(true);
    }
  }, [rideAlongData]);

  const [pli, setPli] = useState<ProductLineItem>({
    ...lineItem,
    ...lineItemSku
  });
  const [customOptionsVariables, setCustomOptionVariables] = useState({
    productId: data?.id,
    fullSkuId: pli?.sku?.inventory?.fullSkuId
  });

  const [isCustomRug, setIsCustomRug] = useState(
    pli?.customProductOptions?.customProductInformation?.customProductType ===
      "rug"
  );

  const { maxQuantity, skuAvailabilityStatus } = useQuantity({
    country,
    sku: pli?.sku?.inventory?.fullSkuId,
    maxQuantity: DEFAULT_MAX_QUANTITY
  });

  const addOnsExist = useMemo(
    () => !!data?.productAddons?.productAddonsInfo?.length,
    [data?.productAddons]
  );

  const shouldDisplayAddOns = data?.productAddonDisplayOptions
    ? ["Checkbox", "Checkbox and Panel"].includes(
        data?.productAddonDisplayOptions || ""
      )
    : true;

  const metaSkuPricing = useMemo(() => {
    return !isEmpty(multiSkuComponentsDataV2)
      ? multiSkuPriceMapper(multiSkuComponentsDataV2, country!)
      : null;
  }, [multiSkuComponentsDataV2, country]);

  useEffect(() => {
    // this will be executed only for estore
    if (PDP_DEFAULT_QTY !== 0) {
      if (pli?.sku?.inventory?.inventoryRemaining !== "0") {
        setQty(1);
      } else {
        setQty(0);
      }
    }
  }, [pli?.sku?.inventory?.atgSkuId]);

  useDidMountEffect(() => {
    // This is only for PDP version of concierge
    if (PDP_DEFAULT_QTY !== 0) return;

    const inventoryRemaining = pli?.sku?.inventory?.inventoryRemaining;
    const selectedOpts = (pli?.availableOptions ?? [])
      .map(availableOption =>
        availableOption?.options?.find(option => option?.status === "selected")
      )
      .filter(Boolean) as ProductAvailableOption[];

    // Check if all default options are selected
    const isAllAvailableOptionsSelected =
      pli?.availableOptions?.length === selectedOpts.length;

    const shouldUpdateQty =
      (isAllAvailableOptionsSelected && !isQtyChanged) ||
      (data?.customProduct &&
        isAllAvailableOptionsSelected &&
        isAllCustomOptionSelected &&
        !isQtyChanged);

    if (!shouldUpdateQty) {
      if (!isQtyChanged) {
        setQty(0);
      }
      return;
    }

    // Update quantity based on inventory and current quantity
    if (inventoryRemaining !== "0" && inventoryRemaining !== "" && qty === 0) {
      setQty(1);
    } else if (inventoryRemaining === "0" || inventoryRemaining === "") {
      setQty(0);
    } else if (qty > Number(inventoryRemaining)) {
      setQty(1);
    }
  }, [pli?.availableOptions, isAllCustomOptionSelected]);

  // for customProduct only
  useDidMountEffect(() => {
    const { diameter: circleOptions, ...rectangleOptions } = customOptions;
    const { shape, rugTrims } =
      pli?.customProductOptions?.customProductInformation ?? {};

    if (shape === "rectangle") {
      for (let rectangleOptionKey in rectangleOptions) {
        const { feet } = rectangleOptions[rectangleOptionKey];

        if (!feet) {
          setIsAllCustomOptionSelect(false);
          return;
        }
      }

      const { width, length } = rectangleOptions;

      const totalWidth = setFeetAsDecimal({
        feet: Number(width?.feet),
        inches: Number(width?.inches)
      });

      const totalLength = setFeetAsDecimal({
        feet: Number(length?.feet),
        inches: Number(length?.inches)
      });

      setCustomOptionVariables(prevState => ({
        ...prevState,
        fullSkuId: pli?.sku?.inventory?.fullSkuId,
        width: totalWidth,
        length: totalLength
      }));
      if (rugTrims && rugTrim && totalWidth && totalLength) {
        setIsAllCustomOptionSelect(true);
      } else if (!rugTrims && totalWidth && totalLength) {
        setIsAllCustomOptionSelect(true);
      } else {
        setIsAllCustomOptionSelect(false);
      }
    } else if (shape === "round") {
      const { feet, inches } = circleOptions;

      if (feet) {
        const totalDiameter = setFeetAsDecimal({
          feet: Number(feet),
          inches: Number(inches)
        });

        setCustomOptionVariables(prevState => ({
          ...prevState,
          fullSkuId: pli?.sku?.inventory?.fullSkuId,
          diameter: totalDiameter
        }));
        if (rugTrims && rugTrim && feet) {
          setIsAllCustomOptionSelect(true);
        } else if (!rugTrims && feet) {
          setIsAllCustomOptionSelect(true);
        } else {
          setIsAllCustomOptionSelect(false);
        }
      } else {
        setIsAllCustomOptionSelect(false);
      }
    }
  }, [
    pli?.customProductOptions?.customProductInformation?.shape,
    pli?.sku?.inventory?.fullSkuId,
    rugTrim,
    customOptions
  ]);

  // For customProducts only
  useEffect(() => {
    if (
      pli?.sku?.info?.skuPriceInfo?.customProductErrorCode ===
        "INVALID_CUSTOM_PRODUCT_PRICE" ||
      customOptions?.length?.feet === "" ||
      customOptions?.width?.feet === ""
    ) {
      if (customOptions?.diameter?.feet !== "")
        return setIsCustomOptionSelectionValid(true);
      setIsCustomOptionSelectionValid(false);
    } else setIsCustomOptionSelectionValid(true);
  }, [pli?.sku?.info?.skuPriceInfo?.customProductErrorCode, customOptions]);

  //we can sets product addons data for concierge add to project functionality
  //---DO-NOT-REMOVE---
  useEffect(() => {
    if (pli?.sku?.inventory && pli?.sku?.restrictions && isConcierge) {
      if (addOnsExist) {
        const addOnDataSet = [] as any;
        addOnsData?.map(addon => {
          data?.productAddons?.productAddonsInfo?.map(prod => {
            if (
              addon?.productId === prod?.id &&
              addon?.productSelected &&
              addon?.qty > 0
            ) {
              addOnDataSet?.push({
                ...prod,
                spo: addon?.spo,
                preBillMessage: addon?.preBillMessage,
                fullSkuId: addon?.fullSkuId,
                pricing: addon?.pricing,
                qty: addon?.qty
              });
            }
          });
        });
        setAddonDataAddToProject(addOnDataSet);
      }
    }
  }, [addOnsData]);
  //////////////////////////////////////////////////////////////////

  // Enabling the RelatedProductActions On Addon addOnsData state updated
  useEffect(() => {
    selectedAddOnProduct && addOnsStateChangeCallback(selectedAddOnProduct);
  }, [panelProdConfigurationData, addOnsData]);
  //////////////////////////////////////////////////////////////////

  // for customProduct only
  const customProductConfig = useMemo(() => {
    const { diameter: circleOptions, ...rectangleOptions } = customOptions;
    const { shape } = pli?.customProductOptions?.customProductInformation || {};
    if (pli?.sku?.info?.skuPriceInfo?.sendCwCustomProductCode == false) {
      return {};
    }

    if (shape === "round") {
      const { feet, inches } = circleOptions;
      const parsedFeet = Number(feet);
      const parsedInches = Number(inches);
      return yn(env.FEATURE_PDP_CART_BROKER) || giftRegistryDialog
        ? {
            diameter: setFeetInchesAsDecimal({
              feet: parsedFeet,
              inches: parsedInches
            }),
            width: 0,
            length: 0,
            trim:
              pli?.customProductOptions?.customProductInformation?.rugTrims?.find(
                ({ id }) => id === rugTrim
              )?.code ?? "",
            type: "rug",
            fulfillmentCode:
              pli?.customProductOptions?.customProductInformation
                ?.cwCustomProductCode ?? ""
          }
        : {
            customProductDiameter: String(
              setFeetAsDecimal({ feet: parsedFeet, inches: parsedInches })
            ),
            customProductTrim: rugTrim,
            customProductType: "rug",
            rug: true
          };
    } else if (shape === "rectangle") {
      const { width, length } = rectangleOptions;
      const parsedWidth = {
        feet: Number(width?.feet),
        inches: Number(width?.inches)
      };
      const parsedLength = {
        feet: Number(length?.feet),
        inches: Number(length?.inches)
      };
      const totalWidth =
        yn(env?.FEATURE_PDP_CART_BROKER) || giftRegistryDialog
          ? setFeetInchesAsDecimal(parsedWidth)
          : setFeetAsDecimal(parsedWidth);
      const totalLength =
        yn(env?.FEATURE_PDP_CART_BROKER) || giftRegistryDialog
          ? setFeetInchesAsDecimal(parsedLength)
          : setFeetAsDecimal(parsedLength);

      //ignore fulfillmentCode for custom rugs SR-72
      const fulfillmentCode = pli?.sku?.info?.skuPriceInfo
        ?.sendCwCustomProductCode
        ? pli?.customProductOptions?.customProductInformation
            ?.cwCustomProductCode ?? ""
        : undefined;

      return yn(env?.FEATURE_PDP_CART_BROKER) || giftRegistryDialog
        ? {
            length: totalLength,
            width: totalWidth,
            trim:
              pli?.customProductOptions?.customProductInformation?.rugTrims?.find(
                ({ id }) => id === rugTrim
              )?.code ?? "",
            type: "rug",
            fulfillmentCode: fulfillmentCode
          }
        : {
            customProductLength: String(totalLength),
            customProductWidth: String(totalWidth),
            customProductTrim: rugTrim,
            customProductType: "rug",
            rug: true
          };
    } else {
      return {};
    }
  }, [customOptions, pli, rugTrim, giftRegistryDialog]);

  // for customProduct only
  const customSelectedOptions = useMemo(() => {
    let selectedOptions: any =
      pli.availableOptions
        ?.map(
          availableOption =>
            availableOption?.options?.find(
              option => option?.status === "selected"
            ) as ProductAvailableOption
        )
        ?.filter(availableOption => !!availableOption) ?? [];

    const { diameter: circleOptions, ...rectangleOptions } = customOptions;
    const { shape, rugTrims } =
      pli?.customProductOptions?.customProductInformation || {};
    let allOptions = [...selectedOptions];

    if (shape === "round") {
      const { feet, inches } = circleOptions || {};
      const totalDiameter = `${feet}&#8242; ${inches || "0"} &#8243;`;
      allOptions = [...allOptions, { type: "Diameter", value: totalDiameter }];
    } else if (shape === "rectangle") {
      const { width, length } = rectangleOptions;
      const totalWidth = `${width?.feet}&#8242; ${
        width?.inches || "0"
      } &#8243;`;
      const totalLength = `${length?.feet}&#8242; ${
        length?.inches || "0"
      } &#8243;`;
      allOptions = [
        ...allOptions,
        { type: "Width", value: totalWidth },
        { type: "Length", value: totalLength }
      ];
    }
    if (rugTrims) {
      allOptions?.push({
        type: "Rug Trim",
        value: rugTrims?.find(({ id }) => id === rugTrim)?.value ?? ""
      });
    }
    return allOptions;
  }, [customOptions, pli, rugTrim]);

  //add all item to cart handler
  const handleAddAllProductChange = () => {
    const itemOptions = {
      productId: data?.id,
      sku:
        lineItem?.sku?.inventory?.fullSkuId ||
        pli?.sku?.inventory?.fullSkuId ||
        "",

      quantity: qty,
      brand,
      giftTo: giftCardTo ?? "",
      giftFrom: giftCardFrom ?? "",
      giftMessage: "",
      ...(monogramOrder?.fontCode ||
      monogramOrder?.fontColorCode ||
      monogramOrder?.borderCode
        ? { monogram: cleanMonogramOrder(monogramOrder) }
        : null),
      ...(!isEmpty(customProductConfig)
        ? { customInfo: customProductConfig }
        : null),
      lineItemIndex: lineItemIndex,
      displayName: data?.displayName,
      name: pli?.sku?.info?.name,
      imageUrl: productImageUrl,
      spo: pli?.sku?.restrictions?.spo,
      preBillMessage: pli?.sku?.restrictions?.preBillMessage
        ? pli.sku?.restrictions?.preBillMessage
        : pli.sku?.restrictions?.returnPolicyMessage || "",
      options: customSelectedOptions,
      salePriceLabel: data?.priceRangeDisplay?.salePriceLabel || "",
      pricing: pli?.sku?.info?.skuPriceInfo
    };
    handleAddAllItemToCart?.(itemOptions);
  };

  useEffect(
    () =>
      selectedRugTrimSwatchId
        ? setRugTrim(selectedRugTrimSwatchId)
        : setRugTrim(""),
    [selectedRugTrimSwatchId]
  );

  useDidMountEffect(() => {
    if (lineItem) {
      setPli({ ...lineItem, ...lineItemSku });
    }
  }, [lineItem, lineItemSku]);

  useDidMountEffect(() => {
    if (
      addAllToCartFlag &&
      (lineItem?.sku?.inventory?.fullSkuId || pli?.sku?.inventory?.fullSkuId) &&
      lineItemIndex !== undefined
    ) {
      if (qty === 0) {
        handleRemoveItemFromAddAll?.(lineItemIndex);
      } else {
        handleAddAllProductChange();
      }
    }
    onChangeCb?.(selectedOptions, qty);
  }, [qty, postalCode]);

  const calculateSelectedOpts = lineItem => {
    return (
      lineItem?.availableOptions
        ?.map(
          availableOption =>
            availableOption?.options?.find(
              option => option?.status === "selected"
            ) as ProductAvailableOption
        )
        ?.filter(availableOption => !!availableOption) ?? []
    );
  };

  const checkAddAllToCartConditions = (
    data,
    lineItem,
    selectedOpts,
    isAllCustomOptionSelected,
    handleAddAllProductChange,
    handleRemoveItemFromAddAll,
    lineItemIndex
  ) => {
    if (
      ((!data?.customProduct &&
        lineItem?.availableOptions?.length === selectedOpts?.length) ||
        (data?.customProduct &&
          lineItem?.availableOptions?.length === selectedOpts?.length &&
          isAllCustomOptionSelected)) &&
      (lineItem?.sku?.inventory?.fullSkuId || pli?.sku?.inventory?.fullSkuId)
    ) {
      handleAddAllProductChange();
    } else if (lineItemIndex !== undefined) {
      handleRemoveItemFromAddAll?.(lineItemIndex);
    }
  };

  const handleGiftCardValueExceed = (
    selectedOpts,
    qty,
    setGiftCardValueExceed
  ) => {
    if (
      selectedOpts?.length > 0 &&
      lineItem?.sku?.info?.name?.includes("Gift Card")
    ) {
      const totalAmount =
        Number(selectedOpts?.[0]?.value?.replace("$", "")) * qty;
      setGiftCardValueExceed(totalAmount > 10000);
    }
  };

  const checkMonogramOrder = (monogramOrder, setIsMonogrammed) => {
    if (monogramOrder) {
      const { description, lines } = monogramOrder;
      const isMonogrammed = !!description && !!lines && !!lines.length;
      if (setIsMonogrammed) setIsMonogrammed(isMonogrammed);
    }
  };

  useDidMountEffect(() => {
    const selectedOpts = calculateSelectedOpts(lineItem);

    selectedIds.current = selectedOpts;
    setSelectedOptions(selectedOpts);

    if (addAllToCartFlag) {
      checkAddAllToCartConditions(
        data,
        lineItem,
        selectedOpts,
        isAllCustomOptionSelected,
        handleAddAllProductChange,
        handleRemoveItemFromAddAll,
        lineItemIndex
      );
    }

    handleGiftCardValueExceed(selectedOpts, qty, setGiftCardValueExceed);
    checkMonogramOrder(monogramOrder, setIsMonogrammed);
  }, [
    lineItem?.availableOptions,
    monogramOrder,
    customProductConfig,
    pli?.sku?.inventory?.fullSkuId
  ]);

  const isMultiSku = id => {
    return id ? ["m", "M"]?.includes(id[0]) : false;
  };

  const getSelectedIds = selectedOptionsSet => {
    const uniqueOptionIds = new Set();
    selectedOptionsSet?.forEach(obj => {
      obj.matchedOptionIds.forEach(optionId => {
        uniqueOptionIds.add(optionId);
      });
    });

    const uniqueOptionIdsArray = Array.from(uniqueOptionIds);
    return uniqueOptionIdsArray;
  };

  const setOptionsVisibility = useCallback(
    optionsSet =>
      pli.availableOptions?.map(optionSet => {
        const matchingOptionSet = optionsSet?.find(
          secondOptionSet =>
            secondOptionSet.optionTypeId === optionSet.optionTypeId
        );

        const matchingOptionIds = optionSet?.options
          .filter(option => option.status === "selected")
          .map(option => option.id);

        const isOptionSetVisible =
          (matchingOptionSet &&
            matchingOptionSet.options.some(option =>
              matchingOptionIds.includes(option.id)
            )) ||
          false;

        return {
          optionTypeId: optionSet.optionTypeId,
          optionType: optionSet.type,
          isOptionSetVisible,
          matchedOptionIds: matchingOptionIds
        };
      }),
    [pli]
  );

  const isAddOnFullyConfigured = () => {
    return addOnsData?.every(addOn => {
      const addOnSelectedOptions = getSelectedIds(
        setOptionsVisibility(addOn.selectedOptions)
      );
      return addOnSelectedOptions?.length === addOn.selectedOptions?.length;
    });
  };

  const isAddOnNotFullyConfigured = !isAddOnFullyConfigured();

  const isDisabled =
    !skuDetails?.fullSkuId ||
    pli?.availableOptions?.length !== selectedOptions?.length ||
    skuLoading ||
    qty <= 0 ||
    giftCardValueExceed ||
    (addOnsExist &&
      selectedAddOnsNotFullyConfigured &&
      isAddOnNotFullyConfigured) ||
    skuAvailabilityStatus?.availabilityStatus === "unavailable";

  // DO NOT TOUCH
  const isEnableCustomRug = useMemo(() => {
    if (
      pli?.customProductOptions?.customProductInformation?.customProductType ===
        "rug" &&
      data?.customProduct &&
      loading
    ) {
      return false;
    }
    return true;
  }, [
    pli?.customProductOptions?.customProductInformation,
    data.customProduct,
    loading
  ]);

  const showItemNumber = !isDisabled;

  const handleAddToGiftRegistryClick = useCallback(() => {
    setGiftRegistryDialog(true);
  }, []);

  const onProductConfigChange = (
    value: string,
    options: ProductAvailableOption[]
  ) => {
    const selectedOption = options?.find(option => option?.id === value);
    selectedIds.current = [
      ...selectedOptions?.filter(
        selectedOption =>
          !options?.find(option => option.id === selectedOption.id)
      ),
      ...(selectedOption ? [selectedOption] : [])
    ];
    setSelectedOptions(selectedIds?.current);
    setIsQtyChanged(false);
    // Some event occurs in the child component and data needs to be sent to the parent
    // productOptionDropdownData(value); // Call the callback function from the parent and pass the data
    onChangeCb?.(selectedIds?.current, qty);
  };

  const addOnsStateChangeCallback = (addOnData: any) => {
    const remainingAddOnsConfigState =
      addOnsData
        ?.filter(addOn => addOn?.productId !== addOnData?.productId)
        ?.map(addOn => {
          return {
            productId: addOn?.productId,
            productChecked: addOn?.productSelected,
            fullyConfigured:
              addOn?.optionsLength === addOn?.selectedOptions?.length &&
              addOn?.fullSkuId
          };
        }) ?? [];

    const selectedAddOnsConfigState = addOnData?.productSelected
      ? [
          ...remainingAddOnsConfigState,
          {
            productId: addOnData?.productId,
            productChecked: addOnData?.productSelected,
            fullyConfigured:
              addOnData?.optionsLength === addOnData?.selectedOptions?.length
          }
        ]
      : [...remainingAddOnsConfigState];

    const areAllSelectedAddOnsConfigured = selectedAddOnsConfigState
      ?.filter(addOn => addOn?.productChecked)
      ?.every(addOn => addOn?.fullyConfigured);

    setSelectedAddOnsNotFullyConfigured(!areAllSelectedAddOnsConfigured);
  };

  const handleAddonData = (
    addOnData: AddOnDataType,
    addOnConfigModes: AddOnConfigModes
  ) => {
    const optionIds = addOnData?.selectedOptions?.map(
      selectedOption => selectedOption.id
    );

    const productAddOnData = data?.productAddons?.productAddonsInfo.find(
      addOn => addOn?.id === addOnData?.productId
    );
    if (
      optionIds?.length !==
      productAddOnData?.productLineItem?.availableOptions?.length
    ) {
      setSelectedAddOnsNotFullyConfigured(false);
    } else {
      setSelectedAddOnsNotFullyConfigured(true);
    }
    setSelectedAddOnProduct(addOnData);
    onAddonConfigChangeCb?.(addOnData, addOnConfigModes);
  };

  const onRugConfigChange = (value: string) => {
    setRugTrim(value);
  };

  const handleDiameterInFeetChange = useCallback(
    (value: string) =>
      setCustomOptions(prevCustomOptions => ({
        ...prevCustomOptions,
        diameter: { ...prevCustomOptions?.diameter, feet: value || "" }
      })),
    []
  );

  const handleDiameterInInchesChange = useCallback(
    (value: string) =>
      setCustomOptions(prevCustomOptions => ({
        ...prevCustomOptions,
        diameter: { ...prevCustomOptions?.diameter, inches: value }
      })),
    [customOptions]
  );

  const handleLengthInFeetChange = useCallback(
    (value: string) =>
      setCustomOptions(prevCustomOptions => ({
        ...prevCustomOptions,
        length: { ...prevCustomOptions?.length, feet: value }
      })),
    []
  );

  const handleLengthInchesChange = useCallback(
    (value: string) =>
      setCustomOptions(prevCustomOptions => ({
        ...prevCustomOptions,
        length: { ...prevCustomOptions?.length, inches: value }
      })),
    []
  );

  const handleWidthInFeetChange = useCallback(
    (value: string) =>
      setCustomOptions(prevCustomOptions => ({
        ...prevCustomOptions,
        width: { ...prevCustomOptions?.width, feet: value }
      })),
    []
  );

  const handleWidthInInchesChange = useCallback(
    (value: string) =>
      setCustomOptions(prevCustomOptions => ({
        ...prevCustomOptions,
        width: { ...prevCustomOptions?.width, inches: value }
      })),
    []
  );

  const handleCloseAddToCartDialog = useCallback(() => {
    setConfirmed(false);
    setCartDialog(false);
  }, []);

  /** @deprecated use FEATURE_USE_QUANTITY=true with useQuantity() */
  const getQtyValue = useMemo(() => {
    const inventory =
      pli?.sku?.inventory?.inventoryRemaining === null
        ? DEFAULT_MAX_QUANTITY
        : pli?.sku?.inventory?.inventoryRemaining ?? 0;
    if (
      pli?.sku?.info?.maxOrderQty !== undefined &&
      pli?.sku?.info?.maxOrderQty !== null &&
      pli?.sku?.info?.maxOrderQty > 0 &&
      pli?.sku?.info?.maxOrderQty < Number(inventory)
    ) {
      return pli?.sku?.info?.maxOrderQty;
    }
    if (
      data?.metaProduct === true &&
      isMultiSku(pli?.sku?.inventory?.fullSkuId)
    ) {
      return multiSkuComponentsDataV2?.components?.reduce(
        (minInventory, multiSkuComponent) =>
          Number(multiSkuComponent?.inventory?.inventoryRemaining) <
          Number(minInventory)
            ? Number(multiSkuComponent?.inventory?.inventoryRemaining)
            : minInventory,
        DEFAULT_MAX_QUANTITY
      );
    }
    return !pli.sku?.inventory?.inventoryRemaining
      ? DEFAULT_MAX_QUANTITY
      : inventory;
  }, [pli?.sku, multiSkuComponentsDataV2?.components]);

  const getLocationSearch = () => {
    const req = getReqContext();
    return req?.path + req?.query;
  };

  /* this details are used in concierge side for product add to project
    ---DO-NOT-REMOVE---
  */
  const productDetails = useMemo(() => {
    return { productId: data?.id, imageUrl: productImageUrl };
  }, [data?.id, productImageUrl]);
  /* ---------------------------------------------------------------- */

  const triggerAnalyticsEvent = () => {
    if (!processEnvServer) {
      const itemList = [
        {
          displayName: data?.displayName,
          productId: data?.id,
          quantity: null,
          sku: null,
          price: data?.priceRangeDisplay,
          currencyCode:
            currencySymbolToCurrencyCodeMapper[
              data?.priceRangeDisplay?.currencySymbol ?? "$"
            ]
        }
      ];

      analyticsLoader(a =>
        a.emitAnalyticsEvent(
          document.querySelector("#spa-root > *")! as HTMLElement,
          a.EVENTS.SELECT_ITEM.INT_TYPE,
          {
            itemList,
            item_list_name: true
          }
        )
      );
    }
  };

  const configureAvailableOptions = useMemo(() => {
    return options => {
      return options.map(option => {
        const updatedOption = { ...option };
        if (
          parentChosenLineItemOptions &&
          parentChosenLineItemOptionIds?.length
        ) {
          parentChosenLineItemOptionIds.forEach((id: string) => {
            if (id?.includes("_")) {
              const optionsList = id?.split("_");
              if (optionsList?.includes(option.id)) {
                updatedOption.status = "selected";
              }
            } else if (option.id === id) {
              updatedOption.status = "selected";
            } else if (options?.length === 1) {
              updatedOption.status = "selected";
            }
          });
        }
        return updatedOption;
      }) as ProductAvailableOption[];
    };
  }, [parentChosenLineItemOptions]);

  const shouldShowSaleText = (Number(data?.saleInfo?.percentSaleSkus) ?? 0) > 0;

  const selectItemsOnSale =
    Number(data?.saleInfo?.percentSaleSkus) > 0 &&
    Number(data?.saleInfo?.percentSaleSkus) < 100;

  const salePercentageRangeExists =
    data?.saleInfo?.memberSavings?.memberSavingsMax !==
    data?.saleInfo?.memberSavings?.memberSavingsMin;

  const SAVE_ON_SELECT_ITEMS = template(pageContent?.SAVE_ON_SELECT_ITEMS)({
    minPercent: data?.saleInfo?.memberSavings?.memberSavingsMin,
    maxPercent: data?.saleInfo?.memberSavings?.memberSavingsMax
  });

  const SAVE_UP_TO_SELECT_ITEMS = template(
    pageContent?.SAVE_MIN_PERCENT_ON_SELECT_ITEMS
  )({
    minPercent: data?.saleInfo?.memberSavings?.memberSavingsMin
  });

  const saleMessageForPriceComponent =
    shouldShowSaleText && selectItemsOnSale
      ? salePercentageRangeExists
        ? SAVE_ON_SELECT_ITEMS
        : SAVE_UP_TO_SELECT_ITEMS
      : "";

  const getSelectedAddOnProdIds = (addOnsData: AddOnDataType[]): string[] => {
    return (
      addOnsData
        ?.filter(addOn => addOn?.productSelected)
        ?.map(addOn => addOn?.productId)
        ?.filter((id): id is string => !!id) ?? []
    );
  };

  const getPanelProd = (selectedAddOnProdIds: string[], data) => {
    const [panelProd] =
      data?.productAddons?.productAddonsInfo?.filter(
        addOn => !selectedAddOnProdIds?.includes(addOn?.id)
      ) ?? [];
    return panelProd;
  };

  const getPreSelectedOptions = (lineItem, panelProd) => {
    return lineItem?.availableOptions
      ?.map(optionSet => {
        const matchingOptionSet =
          panelProd?.productLineItem?.availableOptions?.find(
            secondOptionSet =>
              secondOptionSet?.optionTypeId === optionSet?.optionTypeId
          );
        const matchingOptionIds = optionSet?.options
          ?.filter(option => option?.status === "selected")
          ?.map(option => option?.id);
        return matchingOptionSet?.options?.filter(option =>
          matchingOptionIds?.includes(option?.id)
        );
      })
      ?.filter(opt => opt !== undefined)
      ?.reduce((acc, currentValue) => {
        return acc?.concat(currentValue || []);
      }, []);
  };

  const handleAddonDataUpdate = (
    panelProd,
    preSelectedOptions: ProductAvailableOption[],
    handleAddonData: Function
  ) => {
    const panelProdSingularPreSelectedOptions =
      panelProd?.productLineItem?.availableOptions
        ?.map(opt => opt?.options?.find(opt => opt?.status === "selected"))
        ?.filter(opt => opt) as ProductAvailableOption[];

    handleAddonData(
      {
        productId: panelProd?.id,
        productSelected: false,
        selectedOptions:
          preSelectedOptions ?? panelProdSingularPreSelectedOptions,
        optionsLength: panelProd?.productLineItem?.availableOptions?.length ?? 0
      },
      {
        panelProdComputation: true
      }
    );
  };

  const getAddOnCartSet = (addOnsData: AddOnDataType[], data) => {
    const addOnCartSet: addOnDataType[] = [];
    addOnsData?.forEach(addon => {
      data?.productAddons?.productAddonsInfo?.forEach(prod => {
        if (addon?.productId === prod?.id && addon?.productSelected) {
          addOnCartSet?.push({
            ...prod,
            productId: prod?.id,
            spo: addon?.spo,
            preBillMessage: addon?.preBillMessage,
            fullSkuId: addon?.fullSkuId,
            pricing: addon?.pricing,
            qty: addon?.qty
          });
        }
      });
    });
    return addOnCartSet;
  };

  const handleAddToCartClick = () => {
    if (!skuDetails?.fullSkuId) return;

    if (addOnsExist) {
      const selectedAddOnProdIds = getSelectedAddOnProdIds(addOnsData);
      const panelProd = getPanelProd(selectedAddOnProdIds, data);

      setPanelProdData(panelProd);

      const preSelectedOptions = getPreSelectedOptions(lineItem, panelProd);

      if (panelProd) {
        handleAddonDataUpdate(panelProd, preSelectedOptions, handleAddonData);
      }

      const addOnCartSet = getAddOnCartSet(addOnsData, data);
      setAddonDataAddToCart(addOnCartSet);
    } else {
      const { sku } = !lineItem?.sku?.info?.skuPriceInfo
        ? ({ ...lineItemSku } as ProductLineItem)
        : lineItem;

      if (!isEmpty(sku) && isNewPDPLayout) {
        const formatAddToCartProduct = {
          selectedOptions,
          userType,
          productInfo: {
            displayName: data?.displayName,
            imageUrl: sku?.info?.imageUrl,
            skuPriceInfo: sku?.info?.skuPriceInfo,
            qty,
            dateString: sku?.fulfillmentEta?.inventoryMessage
              ? `${sku?.fulfillmentEta?.inventoryMessage} ${pageContent?.shippingMethod?.message1?.bode2} ${sku?.fulfillmentEta?.postalCode}`
              : "",
            returnPolicyMessage: !isEmpty(multiSkuComponentsDataV2)
              ? multiSkuComponentsDataV2?.multiSkuSpoReturnMessage
              : sku?.restrictions?.returnPolicyMessage,
            fullSkuId: skuDetails?.fullSkuId,
            delivery: !isEmpty(multiSkuComponentsDataV2)
              ? multiSkuComponentsDataV2?.multiSkuComponentsDeliveryInfo
              : sku?.delivery,
            skuRestriction: sku?.restrictions,
            availabilityStatus: skuAvailabilityStatus?.availabilityStatus
          }
        } as addToCartItemDetailsProps;
        setAddToCartItemDetails(formatAddToCartProduct);
      }
    }

    setCartDialog(true);
  };

  const handleAddToWishlistClick = async () => {
    if (pli?.sku?.inventory && !isDisabled) {
      setWishlistDialog(true);
      if (yn(env.FEATURE_ANALYTICS_FORM_DATALAYER)) {
        analyticsLoader(a => {
          a?.emitAnalyticsEvent(
            document?.querySelector<HTMLInputElement>("#spa-root > *")!,
            a?.EVENTS?.GA4_WISHLIST?.INT_TYPE,
            {
              item: {
                id: data?.id,
                name: data?.displayName,
                category: data?.category ?? "",
                sku: skuDetails?.fullSkuId ?? "",
                quantity: qty,
                pricing: pli?.sku?.info?.skuPriceInfo?.listPrice || 0,
                currencyCode:
                  currencySymbolToCurrencyCodeMapper[
                    data?.priceRangeDisplay?.currencySymbol ?? "$"
                  ]
              },
              item_list_name: "pdp",
              value: (pli?.sku?.info?.skuPriceInfo?.listPrice || 0) * qty
            }
          );
        });
      }
    }
  };

  const showInStockButtons = () => {
    return !pli?.sku?.restrictions?.serviceSku;
  };

  const showInStockButton = heroImageLevel => {
    const shouldShowButton = () =>
      showInStockButtons() &&
      data?.instock?.hasInStock &&
      data?.instock?.showInStockButton;

    if (!heroImageLevel) {
      return shouldShowButton();
    }

    if (!isShellProduct) {
      return shouldShowButton();
    }

    if (data?.metaProduct) {
      return shouldShowButton();
    }

    return false;
  };

  const showSaleButton = () => {
    return (
      showInStockButtons() &&
      data?.sale?.hasSale &&
      data?.sale?.showSaleButton &&
      data?.saleInfo?.percentSaleSkus !== "100.000" &&
      !window?.location?.href?.includes("sale=true") &&
      !window?.location?.href?.includes("sale=sale")
    );
  };

  const summarizedQuantity = useMemo(() => {
    return maxQuantity < qty ? maxQuantity : qty;
  }, [maxQuantity, qty]);

  return {
    inStockReady,
    isShellProduct,
    heroImageLevel,
    monogramOrder,
    data,
    isMetaProduct: data?.metaProduct,
    pli,
    authenticated,
    maxOrderQty: yn(env?.FEATURE_USE_QUANTITY)
      ? maxQuantity
      : Number(getQtyValue),
    customProductConfig,
    userType,
    postalCode: postalCode ?? cookies?.pc,
    isNewPDPLayout,
    isNorthAmerica: ["USA", "CAD", "USD"]?.includes(currencyCode),
    swatchShipMessage: pageContent?.swatchShipMessage,
    loading,
    giftCardTo,
    giftCardFrom,
    rugTrim,
    customOptions,
    isAllCustomOptionSelected,
    isDisabled,
    monogrammable: data?.personalizeInfo?.monogrammable,
    country,
    isConcierge,
    showItemNumber,
    isCustomOptionSelectionValid,
    params,
    multiSkuComponentsDataV2,
    skuAvailabilityStatus,
    giftCardValueExceed,
    spoOverrideMessage,
    skuDetails,
    selectedOptions,
    qty: summarizedQuantity,
    addOnsExist,
    selectedAddOnsNotFullyConfigured,
    isEnableCustomRug,
    isCustomRug,
    selectedIds,
    customSelectedOptions,
    addonDataAddToProject,
    productDetails,
    shouldDisplayAddOns,
    addOnsData,
    swatch: data?.swatch,
    postalCodeDialog,
    internationalFlag,
    surchargeInfoDialog,
    cartDialog,
    metaSkuPricing,
    confirmed,
    addonDataAddToCart,
    panelProdData,
    panelProdConfigurationData,
    addToCartItemDetails,
    wishlistDialog,
    giftRegistryDialog,
    productImageUrl,
    monogramDialog,
    lineItemIndex,
    saleContextFilter,
    isLineItemImageUrlFailed,
    saleMessageForPriceComponent,
    lineItem,
    swatchGroups,
    skuInventoryMessage: lineItem?.sku?.fulfillmentEta?.inventoryMessage || "",

    showInStockButton,
    isMultiSku,
    configureAvailableOptions,
    onProductConfigChange,
    // setIsSwatchImageUpdated,
    setGiftCardTo,
    setGiftCardFrom,
    onRugConfigChange,
    handleDiameterInFeetChange,
    handleDiameterInInchesChange,
    handleWidthInFeetChange,
    handleWidthInInchesChange,
    handleLengthInFeetChange,
    handleLengthInchesChange,
    setSurchargeInfoDialog,
    setMonogramDialog,
    setMonogramOrder,
    setPostalCodeDialog,
    setQty,
    setIsQtyChanged,
    handleAddToCartClick,
    handleAddToWishlistClick,
    handleAddToGiftRegistryClick,
    handleAddonData,
    showSaleButton,
    handleCloseAddToCartDialog,
    setConfirmed,
    onAddOnConfigChange: onAddonConfigChangeCb,
    setWishlistDialog,
    setGiftRegistryDialog,
    getLocationSearch,
    triggerAnalyticsEvent,
    setIsLineItemImageUrlFailed,
    setRideAlongData
  };
};
