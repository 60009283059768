import React, { FC, useState, useCallback, useRef } from "react";
import {
  Grid,
  createStyles,
  makeStyles,
  Theme,
  useMediaQuery,
  Typography,
  IconButton,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Portal,
  FormGroup
} from "utils/material-ui-core";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import he from "he";
import classNames from "classnames";
import memoize from "utils/memoize";
import Drawer from "component-drawer";
import RHCloseIcon from "icon-close";
import { CheckboxType } from "./RefinementOptionData";
import useButtonStyles from "hooks/useButtonStyles";
import useTypographyStyles from "hooks/useTypographyStyles";

import {
  BREAKPOINT_SM,
  BREAKPOINT_MD,
  BREAKPOINT_LG,
  BREAKPOINT_XL
} from "utils/constants";
import {
  SORT,
  SORT_SM,
  FILTER,
  CLEAR_ALL,
  VIEW
} from "resources/rhr-product-gallery-resource.json";
import { Refine } from "dialogs/InStockProducts";
import RHCheckmarkIcon from "icons/RHCheckmarkIcon";
import { SortMenu } from "dialogs/InStockProducts/GridController";
import TailwindDrawer from "@RHCommerceDev/component-tailwind-drawer";
import { useEnv } from "hooks/useEnv";
import yn from "yn";

const DISABLED_HINT_SCROLL_X = "disabled_hint_scroll_x";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    scrollXContainer: {
      marginRight: 30,
      position: "relative",
      flexWrap: "nowrap",
      overflowX: "auto",
      "-ms-overflow-style": "none",
      "scrollbar-width": "none",
      "&::-webkit-scrollbar": {
        display: "none"
      }
    },
    refinementFacet: {
      position: "relative",
      top: "1px",
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      marginRight: 30,
      [theme.breakpoints.up(BREAKPOINT_LG)]: {
        marginRight: 40
      },
      [theme.breakpoints.up(BREAKPOINT_XL)]: {
        marginRight: 60
      }
    },
    refinementGroupTitle: {
      userSelect: "none",
      whiteSpace: "nowrap",
      textTransform: "uppercase",
      "&.sort": {
        marginLeft: 18,
        [theme.breakpoints.up(BREAKPOINT_SM)]: {
          marginLeft: 30
        }
      }
    },
    arrow: {
      // fontSize: 14,
      color: "#808080",
      "&.active": {
        transform: "rotate(180deg)"
      }
    },

    popperBox: {
      padding: "40px 32px",
      paddingTop: "8px",
      backgroundColor: "#F9F7F4 !important",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.1)",
      marginTop: -20,
      minWidth: 300,
      // marginLeft: -32,
      maxHeight: 650,
      overflowY: "auto",
      zIndex: 99
    },

    paper: {
      width: "85%",
      [theme.breakpoints.up("sm")]: {
        width: 370
      },
      [theme.breakpoints.up("md")]: {
        width: 440
      },
      background: "#F9F7F4 !important"
    },

    drawerMenu: {
      background: "rgba(0,0,0,0.6)",
      "& > .MuiBackdrop-root": {
        background: "none !important"
      }
    },

    drawerContent: {
      overflow: "auto",
      padding: "0px 24px 0px 24px",
      marginBottom: "150px",
      [theme.breakpoints.up("sm")]: {
        padding: "0px 66px 0px 66px"
      }
    },

    refinmentOptionGroup: {
      marginBottom: "36px",
      [theme.breakpoints.up("md")]: {
        marginBottom: "29px"
      },
      "&:last-child": {
        marginBottom: 0
      }
    },

    drawerButtons: {
      position: "absolute",
      bottom: 0,
      left: 0,
      padding: "46px 0",
      width: "100%",
      display: "flex",
      background: "#F9F7F4 !important",
      justifyContent: "center"
    },
    hintIcon: {
      position: "absolute",
      top: "50%",
      right: "0px",
      transform: "translateY(-50%)",
      display: "flex",
      justifyContent: "flex-end",
      zIndex: 2,
      background:
        "linear-gradient(90deg, #ffffff00 0%, rgb(255 255 255 / 90%) 90%)",
      width: "42px",

      "& button": {
        cursor: "pointer",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignContent: "center",
        padding: "6.5px",
        backgroundColor: "#FFF",
        border: "1px solid #e6e6e6"
      }
    },

    booleanRefinement: {
      marginRight: 30,
      // [theme.breakpoints.up(BREAKPOINT_LG)]: {
      //   marginRight: 40
      // },
      // [theme.breakpoints.up(BREAKPOINT_XL)]: {
      //   marginRight: 60
      // },

      "& .MuiCheckbox-root": {
        marginLeft: `0 !important`,
        backgroundColor: "transparent !important"
      }
    },

    mobileBooleanRefinement: {
      display: "block",
      padding: theme.spacing(4, 0),
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",

      "&:last-of-type": {
        borderBottom: "none"
      },

      "& .MuiCheckbox-root": {
        marginLeft: `0 !important`,
        marginTop: `0 !important`,
        marginBottom: `0 !important`,
        backgroundColor: "transparent !important"
      }
    },
    sortOptionContainer: {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      marginBottom: 16,
      "&:last-child": {
        marginBottom: 0
      }
    },
    sortMenu: {
      padding: "17px 22px 17px 22px",
      backgroundColor: "#f9f7f4",
      boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.25)",
      width: 270,
      marginTop: "-15px",
      marginLeft: "-143px"
    }
  })
);

const useStylesAccordion = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:first-child": {
        borderTop: "0.5px solid #CCCCCC"
      },
      "&:last-child": {
        borderBottom: "0.5px solid #CCCCCC"
      }
    },
    expanded: {
      margin: "0 !important",
      borderTop: "0.5px solid #CCCCCC",
      borderBottom: "0.5px solid #CCCCCC",
      "& + $expanded": {
        borderTop: "none"
      }
    }
  })
);

const useStylesAccordionSummary = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: "0px",
      backgroundColor: "#F9F7F4 !important",
      display: "flex !important",
      [theme.breakpoints.up(BREAKPOINT_MD)]: {
        backgroundColor: "#F9F7F4 !important"
      }
    },
    expandIcon: {
      "& svg": {
        fontSize: "14px",
        color: "#808080"
      }
    },
    content: {
      margin: "32px 0px",
      [theme.breakpoints.up("md")]: {
        margin: "21px 0px"
      },
      "&.Mui-expanded": {
        marginBottom: "34px"
      }
    }
  })
);

const useStylesAccordionDetail = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "block",
      backgroundColor: "#F9F7F4 !important",
      [theme.breakpoints.up(BREAKPOINT_MD)]: {
        backgroundColor: "#F9F7F4 !important"
      },
      padding: "0 0 32px 0",
      "& > div": {
        padding: "0px"
      }
    }
  })
);

interface SelectedRefinementButtonsProps {
  refinements: Refine[];
  showResetRefinement: Boolean;
  resetRefinement?: (event: React.MouseEvent) => void;
  changeNavState?: (index: number) => void;
}
const SelectedRefinementButtons: FC<SelectedRefinementButtonsProps> = memoize(
  ({ refinements, showResetRefinement, resetRefinement, changeNavState }) => {
    const typographyStyles = useTypographyStyles({
      keys: ["rhBaseCaption"] // font size is added inline for SSR for facet name
    });
    return (
      <>
        {refinements.map((option, index) => {
          if (option.isSelected) {
            return (
              <Button
                key={`selected-option-${index}`}
                id={`refineMenuDropdown_clear-${option?.label}-btn`}
                style={{
                  padding: "4px 14px",
                  borderRadius: "32px",
                  backgroundColor: "#F9F7F4",
                  marginRight: 8,
                  marginBottom: 8,
                  flexShrink: 0
                }}
              >
                <Typography
                  className={typographyStyles.rhBaseCaption}
                  style={{
                    textTransform: "capitalize"
                  }}
                >
                  {he.decode(option?.label?.toLowerCase())}
                </Typography>
                <RHCloseIcon
                  style={{ width: 8, marginLeft: 8 }}
                  onClick={() => changeNavState(index)}
                />
              </Button>
            );
          }
        })}

        <Typography
          id="refineInstockDialog_clearAll-btn"
          onClick={e => resetRefinement(e)}
          style={{
            display: showResetRefinement ? "block" : "none",
            cursor: "pointer",
            marginLeft: 8,
            marginBottom: 8,
            textDecoration: "underline",
            textDecorationColor: "#808080",
            textUnderlineOffset: "2px"
          }}
          className={typographyStyles.rhBaseCaption}
        >
          {CLEAR_ALL}
        </Typography>
      </>
    );
  }
);

interface RefineSearchMenuV2Props {
  refinementMenus: SearchRefinementMenu[];
  sortMenu: SortMenu[];
  handleSorting: (index: number) => void;
  totalCount?: number;
  resultTabs?: Array<SearchResultTab> | null;
  resetRefinement?: (event: React.MouseEvent) => void;
  showResetRefinement?: Boolean;
  sizeFacets?: string[];
  materialFacets?: string[];
  selectedRefinementContainer?: HTMLDivElement | null;
  categoryId?: string;
  isSale?: boolean;
  loading?: boolean;
  booleanFacet?: SearchRefinementMenu[];
  refine?: Refine[] | null;
  updateQueryAndRefetch: (index: number) => void;
}

const RefineSearchMenuV2: FC<RefineSearchMenuV2Props> = ({
  refinementMenus,
  sortMenu,
  handleSorting,
  totalCount,
  resultTabs,
  resetRefinement,
  showResetRefinement,
  sizeFacets,
  materialFacets,
  selectedRefinementContainer,
  categoryId,
  isSale,
  loading,
  booleanFacet,
  refine,
  updateQueryAndRefetch
}) => {
  const classes = useStyles();
  const classesAccordion = useStylesAccordion();
  const classesAccordionSummary = useStylesAccordionSummary();
  const classesAccordionDetail = useStylesAccordionDetail();
  const env = useEnv();
  const FEATURE_TAILWIND_COMPONENTS = yn(env.FEATURE_TAILWIND_COMPONENTS);
  const DrawerComponent = FEATURE_TAILWIND_COMPONENTS ? TailwindDrawer : Drawer;
  const [drawerOpen, setDrawerOpen] = useState(false);

  const scrollableContainer = useRef<HTMLDivElement | null>(null);
  const buttonStyles = useButtonStyles({
    keys: ["primaryBlackBtn", "secondaryBtn", "unstyledBtn"]
  });
  const typographyStyles = useTypographyStyles({
    keys: ["rhBaseLink1", "rhBaseCaption", "rhBaseBody2"]
  });

  const smDown = useMediaQuery<Theme>(theme => theme.breakpoints.down("sm"));

  const mdUp =
    useMediaQuery<Theme>(theme => theme.breakpoints.up("md")) || !smDown;

  const handleDrawer = useCallback(value => {
    setDrawerOpen(value);
  }, []);

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      ref={scrollableContainer}
      id="instockFilterId"
      className={classes.scrollXContainer}
    >
      {!smDown ? (
        <>
          {(refine ?? []).map((item, index) => (
            <Grid
              item
              key={`key to be replaced`}
              className={classes.booleanRefinement}
            >
              <FormGroup>
                <CheckboxType
                  key={`option-sale-view-panel`}
                  id={`refinementOptionData_checkbox-${item?.label}-view-panel`}
                  option={item}
                  handleChangeNavState={() => updateQueryAndRefetch(index)}
                  // refinement={{name: 'Availability'}}
                  labelStyle={{
                    textTransform: "uppercase",
                    color: "#CA2022",
                    fontSize: 11
                  }}
                  // labelClassName={labelClassName}
                />
              </FormGroup>
            </Grid>
          ))}
          <Portal container={selectedRefinementContainer}>
            {Object.values((refine ?? []).filter(e => e.isSelected)).flat()
              .length > 0 && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingTop: "16px",
                  flexWrap: "wrap"
                }}
              >
                <SelectedRefinementButtons
                  refinements={refine ?? []}
                  showResetRefinement={!!showResetRefinement}
                  resetRefinement={resetRefinement}
                  changeNavState={updateQueryAndRefetch}
                />
              </div>
            )}
          </Portal>
        </>
      ) : (
        <>
          <Typography
            className={classNames([
              typographyStyles.rhBaseLink1,
              classes.refinementGroupTitle
            ])}
            style={{
              textDecoration: "underline",
              textTransform: "uppercase"
            }}
            onClick={() => handleDrawer(true)}
          >
            {FILTER}
          </Typography>
          <Typography
            className={classNames([
              typographyStyles.rhBaseLink1,
              `${classes.refinementGroupTitle} sort`
            ])}
            style={{
              textDecoration: "underline",
              textTransform: "uppercase"
            }}
            onClick={() => handleDrawer(true)}
          >
            {mdUp ? SORT : SORT_SM}
          </Typography>
        </>
      )}

      {smDown && (
        <DrawerComponent
          transitionDuration={225}
          className={classes.drawerMenu}
          anchor="right"
          open={drawerOpen}
          PaperProps={{
            className: FEATURE_TAILWIND_COMPONENTS
              ? "w-[85%] sm:w-[370px] md:w-[440px] bg-[#F9F7F4]"
              : classes.paper
          }}
          onClose={() => handleDrawer(false)}
        >
          <Grid container direction="row" justify="flex-end">
            <Grid item onClick={() => handleDrawer(false)}>
              <IconButton
                style={{ margin: "20px" }}
                className={buttonStyles.unstyledBtn}
              >
                <RHCloseIcon style={{ fontSize: "16px" }} />
              </IconButton>
            </Grid>
          </Grid>

          <div className={classes.drawerContent}>
            {refine.map((item, index) => {
              return (
                <Grid
                  item
                  key={`${item?.label}-${index}`}
                  className={classes.mobileBooleanRefinement}
                >
                  <FormGroup>
                    <CheckboxType
                      key={`option-sale-view-panel`}
                      id={`refinementOptionData_checkbox-${item?.label}-view-panel`}
                      option={item}
                      handleChangeNavState={() => updateQueryAndRefetch(index)}
                      labelStyle={{
                        textTransform: "uppercase",
                        color: "#CA2022",
                        fontSize: 11
                      }}
                    />
                  </FormGroup>
                </Grid>
              );
            })}
            <Accordion
              classes={classesAccordion}
              square
              elevation={0}
              key={`facet-accordion-sale`}
            >
              <AccordionSummary
                classes={classesAccordionSummary}
                style={{
                  alignItems: "center"
                }}
                expandIcon={
                  <ExpandMoreIcon
                    style={{
                      marginTop: "0px"
                    }}
                  />
                }
                aria-controls={`panel-sort-content`}
                id={`panel1a-sort-header`}
              >
                <div>
                  <Typography
                    className={typographyStyles.rhBaseCaption}
                    style={{
                      textTransform: "uppercase",
                      fontSize: "11px",
                      lineHeight: "13px",
                      letterSpacing: "0.04em"
                    }}
                  >
                    {SORT}
                  </Typography>
                </div>
              </AccordionSummary>

              <AccordionDetails classes={classesAccordionDetail}>
                <div>
                  {sortMenu.map((menu, index) => (
                    <div
                      className={classes.sortOptionContainer}
                      key={`sort-${index}`}
                    >
                      <Typography
                        key={`sortOption-${index}`}
                        className={classNames([
                          typographyStyles.rhBaseBody2
                          //classes.sortOption
                        ])}
                        style={{
                          textTransform: "capitalize",
                          marginRight: 12
                        }}
                        onClick={() => handleSorting(index)}
                      >
                        {menu.label}
                      </Typography>
                      <div>
                        {menu.selected && (
                          <RHCheckmarkIcon style={{ fontSize: 15 }} />
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
          <div className={classes.drawerButtons}>
            {resetRefinement && (
              <Button
                id="refineMenuDropdown_clearAll-btn"
                style={{ marginRight: 11 }}
                className={buttonStyles.secondaryBtn}
                onClick={e => {
                  resetRefinement(e);
                }}
              >
                {CLEAR_ALL}
              </Button>
            )}
            <Button
              id="refineMenuDropdown_view-btn"
              className={buttonStyles.primaryBlackBtn}
              onClick={() => handleDrawer(false)}
            >
              {VIEW}
            </Button>
          </div>
        </DrawerComponent>
      )}
    </Grid>
  );
};

RefineSearchMenuV2.defaultProps = {
  refinementMenus: [],
  sortMenu: [],
  totalCount: 0,
  resultTabs: []
};

export default memoize(RefineSearchMenuV2);
