import {
  Button,
  Theme,
  createStyles,
  makeStyles,
  useMediaQuery,
  useTheme
} from "@material-ui/core";
import { useEnv } from "hooks/useEnv";
import RHAddIcon from "icon-add";
import React from "react";
import { FONT_BARON_SANS } from "utils/constants";
import memoize from "utils/memoize";
import yn from "yn";

const useStyles = props =>
  makeStyles((theme: Theme) =>
    createStyles({
      button: {
        cursor: props.disabled ? "default" : "pointer",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        padding: "5px 0",
        fontSize: theme.typography.pxToRem(12),
        color: "#666666",
        "&:hover": {
          color: props.disabled ? "#666666" : "#000000"
        },
        width: "",
        height: "",
        gap: 0
      },

      button2: {
        cursor: props.disabled ? "default" : "pointer",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        marginLeft: "32px",
        fontSize: "12px",
        fontFamily: FONT_BARON_SANS,
        color: "#201F1F",
        lineHeight: "16px",
        fontWeight: 400,
        letterSpacing: "0.1px",
        gap: 0
      },
      button1: {
        cursor: props.disabled ? "default" : "pointer",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        padding: "5px 0",
        marginLeft: "32px",
        fontSize: "12px",
        color: "#201F1F",
        fontWeight: 400,
        "&:hover": {
          color: props.disabled ? "#666666" : "#000000"
        },
        width: "",
        height: "14.4px",
        letterSpacing: "0.48px",
        gap: 0
      },
      textStyle: {
        fontFamily: "RHSans-Roman",
        fontSize: "12px"
      },
      iconStyle: {
        minHeight: !props.smUp ? 33 : 24,
        marginRight: 12,
        fontSize: theme.typography.pxToRem(12),
        color: ""
      },
      iconStyle1: {
        marginTop: "1px",
        minHeight: 1,
        marginRight: 1,
        fontSize: theme.typography.pxToRem(12),
        color: ""
      },
      tabOnlyTextAlign: {
        [theme.breakpoints.between("sm", "md")]: {
          whiteSpace: "nowrap"
        }
      }
    })
  );
const env = useEnv();
const FEATURE_CART_REFINEMENT_V2 = yn(env?.FEATURE_CART_REFINEMENT_V2);
const ProductAddToButton = ({
  id,
  text,
  onClick,
  disabled,
  isCartPage,
  ...rest
}: {
  id?: string;
  text: string;
  onClick: () => void;
  disabled?: boolean;
  [key: string]: any;
}) => {
  const theme = useTheme();
  const smUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("sm"));

  const classes = useStyles({ disabled })();
  const handleTextLinkClick = () => {
    if (!disabled) {
      onClick();
    }
  };
  return (
    <>
      {FEATURE_CART_REFINEMENT_V2 && isCartPage ? (
        <div
          id={id}
          className={classes.button2 + " " + classes.tabOnlyTextAlign}
          onClick={handleTextLinkClick}
          role="button"
          tabIndex={0}
        >
          <RHAddIcon className={classes.iconStyle1} />
          {text}
        </div>
      ) : rest?.isCustomProduct || smUp ? (
        <div
          id={id}
          className={
            (FEATURE_CART_REFINEMENT_V2
              ? `flex items-center ml-8 text-xs text-gray-900 font-normal tracking-[0.48px] h-[14.4px] gap-0 md:justify-end ${
                  disabled
                    ? "cursor-default"
                    : "cursor-pointer hover:text-black"
                }`
              : `text-gray-600 flex justify-end items-center py-[5px] text-xs gap-0 ${
                  disabled
                    ? "cursor-default text-gray-600"
                    : "cursor-pointer hover:text-black"
                }`) +
            " " +
            "sm:whitespace-nowrap md:whitespace-normal lg:whitespace-normal"
          }
          onClick={handleTextLinkClick}
          role="button"
          tabIndex={0}
        >
          <RHAddIcon
            className={
              FEATURE_CART_REFINEMENT_V2
                ? "min-h-1 mr-1 text-xs"
                : `${!smUp ? "!min-h-[33px]" : "!min-h-[24px]"} !mr-3 !text-xs`
            }
          />
          {text}
        </div>
      ) : (
        <>
          <Button id={id} onClick={onClick} disabled={disabled} {...rest}>
            <RHAddIcon
              className={`${
                smUp ? "!min-h-[33px]" : "!min-h-[24px]"
              } !mr-3 !text-xs`}
            />
            {text}
          </Button>
        </>
      )}
    </>
  );
};

export default memoize(ProductAddToButton);
