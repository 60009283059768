import React, { FC } from "react";
import { ERelatedProductActions } from "./RelatedProductActions";
import { ERelatedProductActionsProps } from "./types";

export const RelatedProductActions: FC<ERelatedProductActionsProps> = ({
  ...rest
}) => {
  return <ERelatedProductActions {...rest} />;
};

export type RelatedProductActionsProps = ERelatedProductActionsProps;
export default RelatedProductActions;
