import React, { useCallback } from "react";
import { Typography } from "@material-ui/core";
import IncrementIcon from "icons/IncrementIcon";
import memoize from "utils/memoize";

const ProductAddToButtonV2 = ({
  id,
  text,
  onClick,
  disabled,
  ...rest
}: {
  id?: string;
  text: string;
  onClick: () => void;
  disabled?: boolean;
  [key: string]: any;
}) => {
  const handleTextLinkClick = useCallback(() => {
    if (!disabled) {
      onClick();
    }
  }, [disabled, onClick]);
  return (
    <>
      <div id={id} onClick={handleTextLinkClick} className="flex items-center">
        <IncrementIcon
          height="11px"
          width="11px"
          stroke="#898886"
          className="!mr-1.5 !align-middle"
        />
        <Typography
          className="!text-gray-400 !text-xs !leading-[1.33rem] !tracking-[0.1px] !font-normal"
          variant="body2"
        >
          {text}
        </Typography>
      </div>
    </>
  );
};

export default memoize(ProductAddToButtonV2);
