import React, { FC } from "react";
import {
  EReplacementPartProductCardActionsProps,
  EReplacementPartProductCardActions
} from "./ReplacementPartProductActions";
import memoize from "utils/memoize";

export const ReplacementPartProductCardActions: FC<
  EReplacementPartProductCardActionsProps
> = ({ ...rest }) => {
  return <EReplacementPartProductCardActions {...rest} />;
};

export type ReplacementPartProductCardActionsProps =
  EReplacementPartProductCardActionsProps;

export default memoize(ReplacementPartProductCardActions);
