import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { Grid } from "utils/material-ui-core";

interface ReplacementPartSkeletonProps {}

export const ReplacementPartSkeleton = ({}: ReplacementPartSkeletonProps) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <Skeleton height={50} />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Skeleton height={50} />
          </Grid>
          <Grid item xs={12} sm={2} md={2}>
            <Skeleton height={50} />
          </Grid>
          <Grid item xs={12} sm={2} md={2}>
            <Skeleton height={50} />
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <Skeleton height={50} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Skeleton height={50} />
      </Grid>
    </Grid>
  );
};

export default ReplacementPartSkeleton;
