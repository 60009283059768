import React from "react";
import { useQuery } from "@apollo/client";
import ProductAddToButton from "./ProductAddToButton";
import { queryGetGiftRegistryList } from "graphql-client/queries/get-gift-registry-list";
import { useEnv } from "hooks/useEnv";
import yn from "yn";
import { queryGetGiftRegistryLists } from "graphql-client/queries/gift-registry";
import { usePageContent } from "customProviders/LocationProvider";
import ProductAddToButtonV2 from "./ProductAddToButtonV2";
import { useRhUserAtomValue } from "hooks/atoms";
import { GiftRegistryButtonProps } from "./types";
import memoize from "utils/memoize";

const GiftRegistryButton = ({
  handleOnClick,
  disabled,
  isNewPDPLayout,
  ...rest
}: GiftRegistryButtonProps) => {
  const env = useEnv();
  const enabled = yn(env.FEATURE_REACT_GIFT_REGISTRY);
  const { email, id: userId } = useRhUserAtomValue();
  const { pageContent } = usePageContent();
  const { data = {} as Query } = useQuery<Query>(
    enabled ? queryGetGiftRegistryList : queryGetGiftRegistryLists,
    enabled
      ? {
          fetchPolicy: "no-cache",
          variables: {
            email,
            guestId: userId
          },
          skip: !email
        }
      : null
  );

  const hasRegistry = enabled
    ? data?.getGiftRegistryList?.length
    : data?.giftRegistryLists?.giftLists?.length;

  if (hasRegistry) {
    if (isNewPDPLayout) {
      return (
        <ProductAddToButtonV2
          disabled={disabled}
          onClick={handleOnClick}
          color="primary"
          variant="outlined"
          text={pageContent?.Add_to_Registry}
          id="component-relatedProductCard_addToRegistry-btn"
          isCustomProduct={rest?.isCustomProduct}
          isNewPDPLayout={isNewPDPLayout}
        />
      );
    }
    return (
      <ProductAddToButton
        disabled={disabled}
        onClick={handleOnClick}
        color="primary"
        variant="outlined"
        text="ADD TO REGISTRY"
        id="component-relatedProductCard_addToRegistry-btn"
        isCustomProduct={rest?.isCustomProduct}
      />
    );
  }
  return null;
};
export default memoize(GiftRegistryButton);
