import { makeStyles, createStyles, Theme } from "utils/material-ui-core";

import {
  BREAKPOINT_MD,
  BREAKPOINT_SM,
  BREAKPOINT_XL,
  BREAKPOINT_LG,
  BREAKPOINT_XS
} from "utils/constants";

export const useStyles = props =>
  makeStyles((theme: Theme) =>
    createStyles({
      MuiButton: {
        root: {
          color: "#999999"
        }
      },
      containedPrimary: {
        "& .MuiButton-label": {
          fontFamily: props.isNewPDPLayout && "RHSans",
          justifyContent: props.isNewPDPLayout ? "center" : "inherit",
          gap: "16px"
        }
      },
      containerStyle: {
        marginBottom: "36px",
        [theme?.breakpoints?.between(BREAKPOINT_SM, BREAKPOINT_MD)]: {
          marginBottom: "42px"
        },
        [theme?.breakpoints?.down(BREAKPOINT_SM)]: {
          marginBottom: "38px"
        }
      },
      atcContanier: {
        alignItems: "center",
        justifyContent: "center",

        [theme.breakpoints.up(BREAKPOINT_XS)]: {
          rowGap: "4px",
          columnGap: "8px"
        },
        [theme.breakpoints.up(BREAKPOINT_SM)]: {
          rowGap: "4px",
          columnGap: "8px"
        },
        [theme.breakpoints.up(BREAKPOINT_MD)]: {
          rowGap: "4px",
          columnGap: "8px"
        },
        [theme.breakpoints.up(BREAKPOINT_LG)]: {
          rowGap: "4px",
          columnGap: "8px"
        },
        [theme.breakpoints.up(BREAKPOINT_XL)]: {
          rowGap: "4px",
          columnGap: "8px"
        }
      },
      wishistContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "36px",
        [theme.breakpoints.up(BREAKPOINT_XS)]: {
          width: "343px !important"
        },
        [theme.breakpoints.up(BREAKPOINT_SM)]: {
          width: "582px !important"
        },
        [theme.breakpoints.up(BREAKPOINT_MD)]: {
          width: "317px !important"
        },
        [theme.breakpoints.up(BREAKPOINT_LG)]: {
          width: "347px !important"
        },
        [theme.breakpoints.up(BREAKPOINT_XL)]: {
          width: "370px !important"
        }
      },
      addToCartForm: {
        width: "100%",
        [theme.breakpoints.between(BREAKPOINT_SM, BREAKPOINT_MD)]: {
          marginBottom: !props.isNewPDPLayout && "42px"
        },
        [theme.breakpoints.down(BREAKPOINT_SM)]: {
          marginBottom: !props.isNewPDPLayout && "38px"
        }
      },
      signedIn: {
        marginBottom: `${props.isNewPDPLayout ? 0 : 20}px`
      },
      addToRegWishBox: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        gap: "20px",
        width: "343px",
        cursor: props.disabled ? "default" : "pointer",
        [theme.breakpoints.up("sm")]: {
          width: "704px"
        },
        [theme.breakpoints.up("md")]: {
          width: "317px"
        },
        [theme.breakpoints.up("lg")]: {
          width: "347px"
        }
      }
    })
  );
