import React, { FC, useState, useCallback, useEffect, useContext } from "react";
import {
  createStyles,
  makeStyles,
  Theme,
  FormControlLabel,
  Checkbox,
  Typography
} from "@material-ui/core";
import he from "he";
import memoize from "utils/memoize";
import { LoadingContext } from "graphql-client/contexts/LoadingContext";
import useTypographyStyles from "hooks/useTypographyStyles";
import RHCheckmarkIcon from "icon-checkmark";
import { Refine } from "dialogs/InStockProducts";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    unchecked: {
      height: "12px",
      width: "12px",
      border: "0.5px solid #000000"
    },
    checked: {
      height: "12px",
      width: "12px",
      backgroundColor: "#000000",
      border: "0.5px solid #000000",
      boxShadow: "inset 0 0 0px 2px #F9F7F4"
    },
    checkbox: {
      // height: "auto", // Inline style added for this
      // width: "auto",
      border: "none",
      margin: 0,
      marginRight: "8px",
      backgroundColor: "transparent",
      "&:hover": {
        backgroundColor: "transparent"
      }
    },

    cbLabel: {
      "&&": {
        width: "100%",
        margin: 0,
        alignItems: "center",
        "&:last-child": {
          marginBottom: 0
        },
        marginBottom: "36px",
        [theme.breakpoints.up("md")]: {
          marginBottom: "29px"
        }
      },
      "&.Mui-disabled": {
        opacity: "0.5"
      }
    },
    sortOptionContainer: {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      marginBottom: 16,
      "&:last-child": {
        marginBottom: 0
      }
    }
  })
);

export interface CheckboxTypeProps {
  id?: string;
  option: Refine;
  handleChangeNavState: (filterString: string) => void;
  labelStyle?: React.CSSProperties;
  labelClassName?: string;
}

export const CheckboxType: FC<CheckboxTypeProps> = memoize(
  ({ id, option, handleChangeNavState, labelStyle, labelClassName }) => {
    const { isLoading: dataIsLoading } = useContext(LoadingContext);
    const classes = useStyles();
    const [checked, setChecked] = useState(false);
    const typographyStyles = useTypographyStyles({
      keys: ["rhBaseBody2"]
    });

    const optionName = option?.name?.toLowerCase();
    const shouldHideFilterCount =
      optionName === "instock" || optionName === "sale";

    const onCheckboxChange = useCallback(
      e => {
        setChecked(!checked);
        handleChangeNavState();
      },
      [option.isSelected, checked, handleChangeNavState]
    );

    useEffect(() => {
      setChecked(option?.isSelected);
    }, [option?.isSelected]);

    return option.name === "Sort" ? (
      <div className={classes.sortOptionContainer}>
        <Typography
          id={id}
          className={typographyStyles.rhBaseBody2}
          onClick={onCheckboxChange}
          style={{ marginRight: 12 }}
        >
          {option?.label || ""}
        </Typography>
        <div>{checked && <RHCheckmarkIcon />}</div>
      </div>
    ) : (
      <FormControlLabel
        id={id}
        className={classes.cbLabel}
        // disabled={dataIsLoading}
        control={
          <Checkbox
            className={classes.checkbox}
            icon={
              <div
                className={checked ? classes.checked : classes.unchecked}
              ></div>
            }
            checkedIcon={
              <div
                className={checked ? classes.checked : classes.unchecked}
              ></div>
            }
            style={{
              height: "auto",
              width: "auto"
            }}
            name={option?.label || ""}
            onChange={onCheckboxChange}
          />
        }
        label={
          <Typography
            className={labelClassName ?? typographyStyles.rhBaseBody2}
            style={{
              textTransform: "capitalize",
              marginTop: "1px",
              ...labelStyle
            }}
          >
            {shouldHideFilterCount
              ? he.decode(optionName || "")
              : `${he.decode(optionName || "")} (${option?.count})`}
          </Typography>
        }
      />
    );
  }
);
