import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    priceRangeDisplay: {
      "& > .MuiGrid-item:last-child": {
        padding: "15px"
      }
    },
    relatedProductSkuContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "16px",
      maxWidth: "100%",
      minWidth: "100%",
      "& > #image-container-sku": {
        "& > div": {
          padding: "0px"
        },
        gap: "16px",
        margin: "0px"
      },
      "& *": {
        fontFamily: "RHSans-ExtraLight !important",
        color: "#000 !important",
        fontSize: "11px !important",
        fontStyle: "normal",
        fontWeight: "200 !important",
        lineHeight: "120%  !important",
        letterSpacing: "0.44px  !important",
        margin: "0px"
      }
    }
  })
);
