import React, { FC } from "react";
import { ERelatedProductActionsV2 } from "./RelatedProductActionsV2";
import memoize from "utils/memoize";
import { ERelatedProductActionsV2Props } from "./types";

export const RelatedProductActionsV2: FC<ERelatedProductActionsV2Props> = ({
  ...rest
}) => {
  return <ERelatedProductActionsV2 {...rest} />;
};

export type RelatedProductActionsV2Props = ERelatedProductActionsV2Props;
export default memoize(RelatedProductActionsV2);
